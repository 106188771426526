<template>
  <v-form ref="form" v-model="valid" :lazy-validation="lazy" v-if="showScreen">
    <v-container style="max-width: 100% !important">
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        v-show="loading"
      ></v-progress-linear>
      <v-card color="white">
        <v-card-title primary-title class="bg-clr">
          <v-btn fab flat icon class="nospace" @click.native="close">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <span class="headline">&nbsp;Qlik Sense</span>
        </v-card-title>
        <v-card-text>
          <v-layout class="errorbox" v-if="noTrue">
            <v-flex
              >Seems like you don't have any permissions. Please contact
              administrator</v-flex
            >
          </v-layout>
          <v-layout pt-2 style="overflow-x: auto" class="scrollFix">
            <v-btn
              round
              v-permissions="'uae-dashboard'"
              v-if="workingCountry.indexOf('UAE') >= 0"
              @click="
                redirect(
                  links.UaeSalesDashboard,
                  'uae-dashboard',
                  'Inquiry Details'
                )
              "
              class="btnStyle"
              color="orange"
              dark
              >UAE Sales dashboard</v-btn
            >
            <v-btn
              round
              v-permissions="'pak-dashboard'"
              v-if="workingCountry.indexOf('PAK') >= 0"
              @click="
                redirect(
                  links.PakSalesDashboard,
                  'pak-dashboard',
                  'Inquiry Details'
                )
              "
              class="btnStyle"
              color="orange"
              dark
              >PAK Sales dashboard</v-btn
            >

            <v-btn
              round
              v-permissions="'ksa-dashboard'"
              v-if="workingCountry.indexOf('KSA') >= 0"
              @click="
                redirect(
                  links.KsaSalesDashboard,
                  'ksa-dashboard',
                  'Inquiry Details'
                )
              "
              class="btnStyle"
              color="orange"
              dark
              >KSA Sales dashboard</v-btn
            >
            <v-btn
              round
              v-permissions="'pod-dashboard'"
              @click="
                redirect(links.PodDashboard, 'pod-dashboard', 'Inquiry Details')
              "
              class="btnStyle"
              color="orange"
              dark
              >POD Dashboard</v-btn
            >
            <v-btn
              round
              v-if="
                workingCountry.indexOf('UAE') >= 0 ||
                workingCountry.indexOf('KSA') >= 0
              "
              v-permissions="'complete-assignment-level-report'"
              @click="
                redirect(
                  links.completeAssignmentLevelReport,
                  'complete-assignment-level-report',
                  'Inquiry Details'
                )
              "
              class="btnStyle"
              color="orange"
              dark
              >Complete assignment level report</v-btn
            >
          </v-layout>
          <v-flex xs12 pt-4>
            <iframe
              v-if="link"
              target="_blank"
              name="frame"
              style="height: 1000px; width: 100%"
              :src="this.link"
            ></iframe>
          </v-flex>
        </v-card-text>
      </v-card>

      <success-dialog
        content="Customer Edited successful"
        :show="x.registerSuccess"
        :onclose="closeRegistration"
      />
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="x.error"
        class="white--text"
        v-if="x.error"
        >{{ x.error }}</v-snackbar
      >
    </v-container>
  </v-form>
</template>

<script>
import SuccessDialog from "@/components/Common/SuccessDialog";
import { EventBus } from "../../event-bus.js";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import { StorageKeys, JobListTypes } from "../../constants/constants";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  mixins: [checkPermissionsMixin],
  components: {
    SuccessDialog,
  },

  created() {
    function login() {
      console.log();
      function isLoggedIn() {
        return fetch(
          "https://lpe4xujzdwnh1g1.sg.qlikcloud.com/api/v1/users/me",
          {
            method: "GET",
            mode: "cors",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "qlik-web-integration-id": "QXvSbZZj8JH5lav4PxKwgBAxaR2F2M5Y",
            },
          }
        ).then((response) => {
          return response.status === 200;
        });
      }
      return isLoggedIn().then((loggedIn) => {
        if (!loggedIn) {
          const webIntegrationId = "QXvSbZZj8JH5lav4PxKwgBAxaR2F2M5Y";
          console.log(webIntegrationId);
          // check login
          window.location.href =
            "https://lpe4xujzdwnh1g1.sg.qlikcloud.com/login?qlik-web-integration-id=" +
            webIntegrationId +
            "&returnto=" +
            top.location.href;

          // window.location.href =
          //   "https://lpe4xujzdwnh1g1.sg.qlikcloud.com/login?qlik-web-integration-id=" +
          //   + "QXvSbZZj8JH5lav4PxKwgBAxaR2F2M5Y" + "&returnto=" + top.location.href;
          throw new Error("not logged in");
        }
      });
    }
    login();

    this.$eventBus.$emit("off-load");
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
      this.checkRoles();
    }
    EventBus.$on("countryChanged", (val) => {
      this.loading = false;
      this.link = "";
      this.istrue = false;

      this.workingCountry = val;

      if (this.currentTab == "redash")
        if (this.$route.name == "analytics") {
          this.checkRoles();
        }
    });

    // this.redirect(
    //   this.links.KsaSalesDashboard,
    //   "ksa-dashboard",
    //   "Inquiry Details"
    // );
  },
  props: {
    currentTab: String,
  },

  data() {
    return {
      noTrue: false,
      configJwt: "",
      creditLimit: "",
      loading: false,
      link: "",
      showScreen: true,

      links: {
        // jobsNoSalesInvoice:
        //   "https://analytics.trukkin.com/public/dashboards/uyDJKketBJnX2JdL8nFb6jNuUPDVRcifWbjOWVeo?org_slug=default&refresh=60",
        // InquiryDetails:
        //   "https://lpe4xujzdwnh1g1.sg.qlikcloud.com/single/?appid=b4768ee0-d32c-4f5f-a3fc-31a8b3d212a0&sheet=ba1e13db-8c3e-4e94-bb02-ebfa0a450f56&theme=card&opt=ctxmenu,currsel",

        KsaSalesDashboard:
          "https://lpe4xujzdwnh1g1.sg.qlikcloud.com/sense/app/5e8488d7-6551-45c0-817c-6275f535b682/sheet/6d7811f7-3e76-46c2-b161-c019c6a401f2/state/analysis/hubUrl/%2Fexplore%2Fspaces%2Fall",
        completeAssignmentLevelReport:
          "https://lpe4xujzdwnh1g1.sg.qlikcloud.com/single/?appid=8e184148-e6ae-4569-a1a9-48024a54340b&sheet=9f630425-5fc4-4411-9fa1-153134347a3e&theme=breeze&opt=ctxmenu,currsel",

        UaeSalesDashboard:
          "https://lpe4xujzdwnh1g1.sg.qlikcloud.com/sense/app/5e8488d7-6551-45c0-817c-6275f535b682/sheet/c3a490b3-c7c0-421a-87ea-fd2007dd20f4/state/analysis/hubUrl/%2Fexplore%2Fspaces%2Fall",
        PakSalesDashboard:
          "https://lpe4xujzdwnh1g1.sg.qlikcloud.com/sense/app/5e8488d7-6551-45c0-817c-6275f535b682/sheet/cdb2bd2b-94b1-4af0-8495-bba5416592b3/state/analysis/hubUrl/%2Fexplore%2Fspaces%2Fall",

        OperationsDashboard:
          "https://lpe4xujzdwnh1g1.sg.qlikcloud.com/single/?appid=b4aaad38-8bb7-47a6-84ba-74c60b9869bb&sheet=9f630425-5fc4-4411-9fa1-153134347a3e&theme=breeze&opt=ctxmenu,currsel&select=clearall",
        CustomerRevenue:
          "https://lpe4xujzdwnh1g1.sg.qlikcloud.com/single/?appid=d971516d-2a35-408c-819b-2b0868d245ee&sheet=80d023c6-558e-4769-9161-3ebe9b9fd87b&theme=breeze&opt=ctxmenu,currsel",
        PodDashboard:
          "https://lpe4xujzdwnh1g1.sg.qlikcloud.com/sense/app/5e8488d7-6551-45c0-817c-6275f535b682/sheet/2b0c82f9-f66f-4d6b-89ae-3242215819ad/state/analysis/hubUrl/%2Fexplore%2Fspaces%2Fall",
      },
      invoiceDay: "",
      defaultId: "",
      loading: false,
      readonly: true,
      currency: "",
      edit: true,
      creditLimit: "",
      time: "",
      multipleCountry: "",
      countryCodes: [],
      invoicingCity: null,
      workingCountry: "",
      invoicingCountry: "",
      valid: true,
      lazy: false,
      model: [],
      processing: false,
      componentKey: 0,
      search: "",
      loading: false,
      isNoSearchResult: false,

      x: {
        loading: false,
        registerSuccess: false,
        menu: false,
        dateOfBirth: null,
        error: null,
        missingField: null,
      },
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        // phoneNumber: (v) =>
        //   /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
        //   "Please Enter Valid Phone No.",
        phoneNumber: (v) =>
          /^[0-9]{5,15}$/.test(v) || "Please Enter Valid Phone No.",
        counter: (value) =>
          (value != null && value.trim().length <= 20) ||
          "Number should contain maximum 20 characters.",
        vatNumber: (v) =>
          /^([a-zA-Z0-9_-]){1,20}$/.test(v) || "Please Enter Valid Value",
        requiredLocation: (value) =>
          (!!value && !!value.length) || "This field is required.",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
      },
    };
  },
  computed: {
    haserror() {
      return this.x.error !== null;
    },
  },

  watch: {},

  methods: {
    loginqlik() {
      return fetch(
        "https://" + "lpe4xujzdwnh1g1.sg.qlikcloud.com" + "/login/jwt-session",
        {
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "qlik-web-integration-id": "QXvSbZZj8JH5lav4PxKwgBAxaR2F2M5Y",
            Authorization: "Bearer " + this.configJwt,
          },
        }
      ).then((response) => {
        console.log(response);
        if (response.status !== 200) {
          throw new Error("failed to login via jwt");
        }
      });
    },
    fetchQlikSense() {
      let url = `/dmsAdmin/qlick-token`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios
        .get(
          this.constants.apiUrl + url,

          config
        )
        .then(
          (response) => {
            console.log(response.data.data.token);
            this.configJwt = response.data.data.token;
            this.loginqlik();
          },
          (error) => {
            // this.x.error = "Failed to Fetch User Details";
          }
        );
    },
    checkRoles() {
      // this.noTrue = false;
      let userPermissions = JSON.parse(
        localStorage.getItem("permissions")
      ).permissions;
      let redash = {};
      let subRoles = {};

      console.log(this.workingCountry);
      console.log(this.workingCountry.indexOf("KSA"));
      if (this.workingCountry.indexOf("KSA") >= 0) {
        subRoles["ksa-dashboard"] = "KsaSalesDashboard";
        subRoles["complete-assignment-level-report"] =
          "completeAssignmentLevelReport";
      }
      if (this.workingCountry.indexOf("UAE") >= 0) {
        subRoles["uae-dashboard"] = "UaeSalesDashboard";
        subRoles["complete-assignment-level-report"] =
          "completeAssignmentLevelReport";
      }
      if (this.workingCountry.indexOf("PAK") >= 0) {
        // subRoles["inquiries-details"] = "InquiryDetails";
        subRoles["pak-dashboard"] = "PakSalesDashboard";
      }
      subRoles["pod-dashboard"] = "PodDashboard";
      redash = subRoles;
      console.log(subRoles);
      let role = JSON.parse(localStorage.getItem("permissions")).roles;
      for (var i = 0; i < userPermissions.length; i++) {
        if (redash[userPermissions[i].operationName]) {
          console.log(redash[userPermissions[i].operationName]);
          this.noTrue = false;
          this.link = this.links[redash[userPermissions[i].operationName]];
          break;
        } else {
          this.noTrue = true;
        }
      }

      role.filter((x) => {
        if (x.role.roleName == "redash") {
          if (!x.operations.length) {
            this.noTrue = true;
          }
        }
      });
    },
    async checkButtonPermissions(subRole) {
      this.loading = true;
      this.operationName = subRoles;
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = false;
      } else {
        return;
      }
    },
    async checkSave() {
      this.operationName = "edit-default-limit";
      this.loading = true;
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = false;
        this.editDefaults();
      } else {
        return;
      }
    },
    redirectTo(linked) {
      this.link = linked;
    },

    async redirect(linked, role, event) {
      this.loading = true;
      this.operationName = role;
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = false;
        // this.track(event, {
        //   $email: localStorage.getItem("email"),
        //   $name: localStorage.getItem("user"),
        //   WorkingCountry: localStorage.getItem("workingCountry"),
        // });

        this.link = linked;
      } else {
        return;
      }
    },
    close() {
      this.$router.go(-1);
    },

    closeRegistration() {
      this.registerSuccess = false;
      this.$router.go(-1);
    },

    getTime(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getTime2(date) {
      return moment.utc(date).format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn__content {
  white-space: break-spaces !important;
}
.scrollFix:-scrollbar {
  width: 10px;
  height: 10px;
  position: absolute;
}
.scrollFix::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgray;
  border-radius: 10px;
}

.scrollFix::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  position: absolute;
}
.scrollFix::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.scrollFix::-webkit-scrollbar-thumb {
  background-color: lightgray !important;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
}
.track1-ass-btn {
  text-decoration: none;
  font-size: 10px;
  color: #ffffff;
  margin-left: 10px;
  background-color: darkorange;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding-left: 15px;
  padding-right: 15px;
}
.card-outline {
  max-width: 344px;
  border: 2px solid darkorange !important;
  padding: 10px 10px 10px 10px !important;
  border-radius: 5px !important;
}
.expiry {
  width: 100%;
}
.main-heading {
  padding-top: 25px;
  font-size: 25px;
}
.menu,
.menu__content {
  background: white !important;
}
.errorbox {
  padding: 20px;
  text-align: center;
  color: red !important;
  font-size: 20px;
  padding: 10px 10px 10px 10px !important;
}

.jbtn-file {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.jbtn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}
.margin-bottom20 {
  margin-bottom: 20px;
}
.marginTop20 {
  margin-top: 20px;
}
.font16 {
  font-size: 16px;
}
</style>
<style scoped>
.doc-image {
  float: right;
  width: 40%;
}
.ass-btn {
  text-decoration: none;
  color: darkorange;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid darkorange;
}
.grey-btn {
  background-color: grey !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.green-button {
  border-radius: 5px;
  background-color: #63ae63 !important;
  color: #ffffff !important;
  border: 2px solid green !important;
}
.btnStyle {
  margin: 5px;
  width: 275px;
}
.widthbtn {
  width: 196px;
}
.minHeight {
  min-height: 300px;
}
</style>
