<script>
import { Line } from 'vue-chartjs'


export default {
  extends: Line,
  watch: {
      chartdata: {
        handler: function() {
          if(this.chartdata)
          this.renderChart(this.chartdata, this.options)
        },
        deep: true
      }
    },
    
  
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  
  
  mounted () {
    this.renderChart(this.chartdata, this.options)
  },
   
}
</script>
