<template>
  <div class="content">
    <loading :active.sync="loading" />
    <!--cards__grid-->
    <div class="cards__grid">
      <!--aside start-->
      <div class="card__head __full __gray Montserrat-700">
        Customer Summary Dashboard
      </div>
      <div class="__aside">
        <!--aside-sticky-->
        <div class="aside__sticky">
          <!--inner__aside-->
          <div class="inner__aside">
            sss
            <div class="profile-in">
              <!-- <label class="pull-left"dff
                ><img src="../../assets/profile-pic.jpg"
              /></label> -->
              <label class="Montserrat-700">
                <small class="Montserrat-500 __light">Select Customer</small
                ><br />
                <v-autocomplete
                  style="background-color: white !important"
                  attach
                  :items="customerList"
                  :close-on-content-click="true"
                  single-line
                  class="pt-0 currencyTitle"
                  v-model="customerId"
                  @change="
                    resetData();
                    getCustomerDetail(customerId);
                  "
                  label="Select customer"
                ></v-autocomplete>
              </label>
            </div>
            <small class="Montserrat-500 __light">Select Filter</small>
            <div class="profile-in">
              <!-- <p class="Montserrat-700"> -->

              <div class="fs-14 d-flex justify-content-between">
                <v-select
                  class="pt-0 currencyTitle"
                  v-if="overAllFilter != 'Custom'"
                  v-model="overAllFilter"
                  :items="monthsOverall"
                  label="Select filter"
                  persistent-hint
                  @change="allFilter()"
                  return-object
                  single-line
                ></v-select>

                <v-flex
                  xs12
                  class="wrapper"
                  id="overallDate"
                  v-if="overAllFilter == 'Custom'"
                >
                  <ejs-daterangepicker
                    id="overallPicker"
                    :startDate="startOverallVal"
                    :endDate="endOverallVal"
                    :placeholder="overallMark"
                    v-model="dateRangeOverall2"
                  ></ejs-daterangepicker>
                </v-flex>
                <!-- <date-range-picker
                  v-if="overAllFilter == 'Custom'"
                  :opens="'right'"
                  ref="picker"
                  class="pt-0 currencyTitle"
                  :locale-data="{
                    firstDay: 1,
                    Format: 'mm/dd/yyyy',
                    customRangeLabel: 'Custom Range',
                  }"
                  v-model="dateRangeOverall"
                  startText="Custom"
                  :ranges="false"
                  @update="allCustomFilter()"
                >
                  <template
                    v-if="dateRangeOverall.length"
                    slot="input"
                    slot-scope="picker"
                    style="min-width: 350px"
                    >{{ getTime3(picker.startDate) || "" }} -
                    {{ getTime3(picker.endDate || "") }}</template
                  >
                </date-range-picker> -->

                <v-icon
                  color="orange"
                  style="padding-left: 10px; padding-bottom: 10px"
                  @click.native="resetData()"
                  >donut_large</v-icon
                >
              </div>
              <div></div>
            </div>
            <div class="fs-14 d-flex justify-content-between">
              <span
                ><img
                  style="margin-top: -5px; margin-right: 2px; width: 16px"
                  src="../../assets/men.svg"
                /><label class="gray__dark Montserrat-700"
                  >Primary RM</label
                ></span
              >
              <span
                ><label
                  v-if="userDetails[0] && userDetails[0].saleExecutive"
                  class="ml-2 Montserrat-700"
                  >{{ userDetails[0].saleExecutive.name || "N.A" }}</label
                >
                <label v-else class="ml-2 Montserrat-700">Not Assigned</label>
              </span>
            </div>
            <div class="credit__details">
              <span class="Montserrat-700">Credit Details</span><br />
              <p class="Montserrat-600">
                <span>Time</span
                ><span
                  v-if="userDetails[0].invoiceDay"
                  class="__orange Montserrat-700"
                  >{{ userDetails[0].invoiceDay }} Days</span
                >
              </p>
              <p class="Montserrat-600">
                <span>Approval Limit</span>
                <span class="__orange Montserrat-700">{{
                  Math.round(userDetails[0].creditLimit)
                }}</span>
              </p>
              <p class="Montserrat-600">
                <span>Tot. Bal. Available</span>
                <span
                  v-if="userDetails[0].remainingLimit"
                  class="__orange Montserrat-700"
                  >{{ Math.round(userDetails[0].remainingLimit) }}
                </span>
              </p>
            </div>
            <div class="download">
              <div class="__text">
                <p class="Montserrat-600 fs-18">
                  <img
                    src="../../assets/Tradelicense.png"
                    style="width: 23px; margin-right: 9px"
                  />Trade License No.
                </p>
                <p class="gray__dark Montserrat-600">
                  Expires on
                  <span
                    v-if="userDetails[0].tradeLicenseExpiryDate"
                    class="__red"
                    >{{ getTime3(userDetails[0].tradeLicenseExpiryDate) }}</span
                  >
                  <span v-else class="__red">N.A</span>
                </p>
              </div>

              <p
                v-if="userDetails[0].tradeLicense"
                @click="
                  download(userDetails[0].tradeLicense, 'tradeLicenseImage')
                "
                class="d-flex justify-content-between download__btn"
              >
                <span class="__orange Montserrat-600">Download</span>
                <span class="text__right"
                  ><img src="../../assets/download.svg"
                /></span>
              </p>
            </div>
            <div class="download">
              <div class="__text">
                <p class="Montserrat-600 fs-18 gray__dark">
                  <img
                    src="../../assets/Tradelicense.png"
                    style="width: 23px; margin-right: 9px"
                  />VAT No.
                </p>
                <p class="Montserrat-600 fs-18 __gray">
                  {{ userDetails[0].companyVatNumber || "N.A" }}
                </p>
                <p class="gray__dark Montserrat-600">
                  Expires on
                  <span
                    v-if="userDetails[0].vatCertificateExpiryDate"
                    class="__red"
                    >{{
                      getTime3(userDetails[0].vatCertificateExpiryDate)
                    }}</span
                  >
                  <span v-else class="__red">N.A</span>
                </p>
              </div>
              <p
                v-if="userDetails[0].vatCertificate"
                @click="
                  download(userDetails[0].vatCertificate, 'VATcetificate')
                "
                class="d-flex justify-content-between download__btn"
              >
                <span class="__orange Montserrat-600">Download</span>
                <span class="text__right"
                  ><img src="../../assets/download.svg"
                /></span>
              </p>
            </div>
            <div class="download">
              <div class="__text">
                <p class="Montserrat-600 fs-18">
                  <img
                    src="../../assets/Tradelicense.png"
                    style="width: 23px; margin-right: 9px"
                  />Owner Passport
                </p>
                <p class="gray__dark Montserrat-600">
                  Expires on
                  <span
                    v-if="userDetails[0].passportExpiryDate"
                    class="__red"
                    >{{ getTime3(userDetails[0].passportExpiryDate) }}</span
                  >
                  <span v-else class="__red">N.A</span>
                </p>
              </div>
              <p
                v-if="userDetails[0].passPort"
                @click="download(userDetails[0].passPort, 'passport')"
                class="d-flex justify-content-between download__btn"
              >
                <span class="__orange Montserrat-600">Download</span>
                <span class="text__right"
                  ><img src="../../assets/download.svg"
                /></span>
              </p>
            </div>
            <div class="download">
              <div class="__text">
                <p class="Montserrat-600 fs-18">
                  <img
                    src="../../assets/Tradelicense.png"
                    style="width: 23px; margin-right: 9px"
                  />Emirates ID
                </p>
                <p class="gray__dark Montserrat-600">
                  Expires on
                  <span class="__red" v-if="userDetails[0].emirateExpiryDate">{{
                    getTime3(userDetails[0].emirateExpiryDate)
                  }}</span>
                  <span class="__red" v-else>N.A</span>
                </p>
              </div>
              <p
                v-if="userDetails[0].emiratesId"
                @click="
                  download(userDetails[0].emiratesId, 'emirateCertificate')
                "
                class="d-flex justify-content-between download__btn"
              >
                <span class="__orange Montserrat-600">Download</span>
                <span class="text__right"
                  ><img src="../../assets/download.svg"
                /></span>
              </p>
            </div>
          </div>
          <!--inner__aside-->
        </div>
        <!--aside-sticky-->
      </div>
      <!--aside start-->
      <!--aside__content-->
      <div class="aside__content">
        <div class="card__order mb-3">
          <div class="top__grid">
            <div class="bg__white __billing">
              <div
                v-if="!subRoles.includes('billing-section')"
                style="text-align: center"
                class="justify-content-between align-items-center"
              >
                <v-icon color="black" medium>lock</v-icon>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <label style="margin: 0" class="fs-18 __gray Montserrat-700">
                  <img
                    src="../../assets/billing.svg"
                    style="margin-right: 7px; margin-top: -3px"
                  />Billing</label
                >
                <v-flex xs5>
                  <v-select
                    v-model="billingMonth"
                    :items="months"
                    label="Select filter"
                    :disabled="
                      !subRoles.includes('billing-section') || overAllFilter
                    "
                    persistent-hint
                    @change="getBillingDetails()"
                    return-object
                    single-line
                  ></v-select>
                </v-flex>
              </div>
              <ul class="table__list">
                <li class="Montserrat-600">
                  Tot. Invested Capital
                  <span class="Montserrat-700">{{
                    billingDetails.investedCapital || "-"
                  }}</span>
                </li>
                <li class="Montserrat-600">
                  Tot. Outstanding Amount
                  <span class="Montserrat-700">{{
                    billingDetails.outstandingAmount || "-"
                  }}</span>
                </li>
                <li class="Montserrat-600">
                  Tot. Billed Amount<span class="Montserrat-700">{{
                    billingDetails.billedAmount || "-"
                  }}</span>
                </li>
                <li class="Montserrat-600">
                  Tot. Unbilled Amount
                  <span class="Montserrat-700">{{
                    billingDetails.totalUnbilledAmout || "-"
                  }}</span>
                </li>
                <li class="Montserrat-600">
                  Last Invoicing Received
                  <span
                    v-if="billingDetails.lastInvoiceRecieved != 'NA'"
                    class="Montserrat-700"
                    >{{
                      billingDetails.lastInvoiceRecieved
                        ? getTime(billingDetails.lastInvoiceRecieved)
                        : "-"
                    }}</span
                  >
                  <span
                    v-if="billingDetails.lastInvoiceRecieved == 'NA'"
                    class="Montserrat-700"
                    >-</span
                  >
                </li>
              </ul>
            </div>
            <div class="bg__white __economic">
              <div
                v-if="!subRoles.includes('key-economics')"
                style="text-align: center"
                class="justify-content-between align-items-center"
              >
                <v-icon color="black" medium>lock</v-icon>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <label
                  style="margin: 0"
                  class="fs-18 __gray main__align Montserrat-700"
                >
                  <img
                    src="../../assets/billing.svg"
                    style="margin-right: 7px; margin-top: -3px"
                  />Key Economics</label
                >
                <v-flex xs4>
                  <v-select
                    v-model="keyEconomicsMonth"
                    :items="months"
                    label="Select filter"
                    persistent-hint
                    :disabled="
                      !subRoles.includes('key-economics') || overAllFilter
                    "
                    @change="getKeyEconomics()"
                    return-object
                    single-line
                  ></v-select>
                </v-flex>
              </div>
              <ul class="economy__card">
                <li class="Montserrat-600">
                  <span
                    ><img src="../../assets/avg.svg" /> Avg. Gross Margin </span
                  ><span
                    v-if="keyEconomics && keyEconomics.avgGross"
                    class="Montserrat-700"
                    >{{
                      keyEconomics.avgGross.length
                        ? Math.round(keyEconomics.avgGross[0].percentage) + "%"
                        : "-"
                    }}
                  </span>
                  <span v-else class="Montserrat-700">-</span>
                </li>
                <li class="Montserrat-600">
                  <span
                    ><img src="../../assets/avg.svg" /> Local Shipment Gross
                    Margin
                  </span>
                  <span
                    v-if="keyEconomics && keyEconomics.localGross"
                    class="Montserrat-700"
                    >{{
                      keyEconomics.localGross.length
                        ? Math.round(keyEconomics.localGross[0].percentage) +
                          "%"
                        : "-"
                    }}
                  </span>
                  <span v-else class="Montserrat-700">-</span>
                </li>
                <li class="Montserrat-600">
                  <span
                    ><img src="../../assets/avg.svg" /> Cross Border Gross
                    Margin</span
                  >
                  <span
                    v-if="keyEconomics && keyEconomics.crossBorder"
                    class="Montserrat-700"
                    >{{
                      keyEconomics.crossBorder.length
                        ? Math.round(keyEconomics.crossBorder[0].percentage) +
                          "%"
                        : "-"
                    }}
                  </span>
                  <span v-else class="Montserrat-700">-</span>
                </li>
                <li class="Montserrat-600">
                  <span
                    ><img src="../../assets/avg.svg" /> Gross Margin for last 10
                    Shipments</span
                  >
                  <span
                    v-if="keyEconomics && keyEconomics.previousTenAvgGross"
                    class="Montserrat-700"
                    >{{
                      keyEconomics.previousTenAvgGross.length
                        ? Math.round(
                            keyEconomics.previousTenAvgGross[0].percentage
                          ) + "%"
                        : "-"
                    }}
                  </span>
                  <span v-else class="Montserrat-700">-</span>
                </li>
              </ul>
            </div>
            <div class="bg__white __destination">
              <div class="d-flex justify-content-between align-items-center">
                <label
                  style="margin: 0"
                  class="fs-18 __gray main__align Montserrat-700"
                >
                  <img
                    src="../../assets/location.svg"
                    style="margin-right: 7px; margin-top: -3px"
                  />Major Destinations</label
                >
                <v-flex xs4>
                  <v-select
                    v-model="majordestn"
                    :items="months"
                    label="Select filter"
                    :disabled="overAllFilter"
                    persistent-hint
                    @change="getMajorDestination()"
                    return-object
                    single-line
                  ></v-select>
                </v-flex>
              </div>
              <ul class="location__tag" v-if="destinations.length">
                <li
                  class="Montserrat-600"
                  v-for="(item, i) in destinations"
                  :key="i"
                >
                  {{ item.destinationCity }}
                </li>
              </ul>
              <p
                v-if="!destinations.length"
                style="text-align: center; padding-top: 30px"
              >
                No major destinations
              </p>
            </div>
          </div>
          <div class="top__grid">
            <div class="bg__white __analysis">
              <div
                v-if="!subRoles.includes('ageing-section')"
                style="text-align: center"
                class="justify-content-between align-items-center"
              >
                <v-icon color="black" medium>lock</v-icon>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <label style="margin: 0" class="fs-18 Montserrat-700">
                  <img
                    src="../../assets/Ageing.svg"
                    style="margin-right: 7px; margin-top: -3px"
                  />Ageing Receivable Analysis</label
                >
              </div>

              <div
                class="download"
                v-if="CsvUrl"
                @click="download(CsvUrl, 'CSV')"
              >
                <div class="__text">
                  <p class="Montserrat-600 gray__dark">Statement of Account</p>
                  <p
                    class="gray__dark Montserrat-600 d-flex justify-content-between"
                  >
                    <span class="__gray Montserrat-600">Download CSV</span
                    ><button style="margin-top: -22px; margin-right: 6px">
                      <img src="../../assets/download.svg" />
                    </button>
                  </p>
                </div>
              </div>
              <div style="padding: 2px 0px 0px 30px">
                <v-flex
                  v-if="subRoles.includes('ageing-section') && !overAllFilter"
                  xs8
                  class="wrapper"
                  id="ageingDate"
                >
                  <ejs-daterangepicker
                    id="ageingPicker"
                    :placeholder="waterMark"
                    v-model="dateRangeAgeing2"
                    :startDate="startVal"
                    :endDate="endVal"
                  ></ejs-daterangepicker>
                </v-flex>
                <!-- <span class="muted" style="font-size:11px"
                  >Date Range Filter</span
                >
                <date-range-picker
                  ref="picker"
                  class="pt-0 currencyTitle"
                  :locale-data="{
                    firstDay: 1,
                    Format: 'mm/dd/yyyy',
                    customRangeLabel: 'Custom Range',
                  }"
                  v-model="dateRangeAgeing"
                  startText="Custom"
                  :ranges="false"
                  :disabled="
                    !subRoles.includes('ageing-section') || overAllFilter
                  "
                  @update="getAgeingAnalysis()"
                >
                  <template
                    v-if="dateRangeAgeing.length"
                    slot="input"
                    slot-scope="picker"
                    style="min-width: 350px"
                    >{{ getTime3(picker.startDate) || "" }} -
                    {{ getTime3(picker.endDate || "") }}</template
                  >
                </date-range-picker> -->
              </div>

              <ul class="table__list green__layer">
                <li class="Montserrat-600">
                  <span
                    ><img src="../../assets/clock-white.svg" /><label
                      >Not due</label
                    ></span
                  ><span class="Montserrat-700">{{
                    ageingAnalysisDetails.noDueAmount || "-"
                  }}</span>
                </li>
                <li class="Montserrat-600">
                  <span
                    ><img src="../../assets/clock-gray.svg" />Greater than 30
                    days</span
                  ><span class="Montserrat-700">{{
                    ageingAnalysisDetails.thirtyDaysAmount || "-"
                  }}</span>
                </li>
                <li class="Montserrat-600">
                  <span
                    ><img src="../../assets/clock-white.svg" />Greater than 60
                    days</span
                  ><span class="Montserrat-700">{{
                    ageingAnalysisDetails.sixtyDaysAmount || "-"
                  }}</span>
                </li>
                <li class="Montserrat-600">
                  <span
                    ><img src="../../assets/clock-gray.svg" />Greater than 90
                    days</span
                  ><span class="Montserrat-700">{{
                    ageingAnalysisDetails.nintyDaysAmount || "-"
                  }}</span>
                </li>
                <li class="Montserrat-600">
                  <span
                    ><img src="../../assets/clock-white.svg" />Greater than 120
                    days</span
                  ><span class="Montserrat-700">{{
                    ageingAnalysisDetails.oneTwentyDaysAmount || "-"
                  }}</span>
                </li>
                <li class="Montserrat-600">
                  <span
                    ><img src="../../assets/clock-white.svg" />Total receivables </span
                  ><span class="Montserrat-700">{{
                    ageingAnalysisDetails.outstandingAmount || "-"
                  }}</span>
                </li>
              </ul>
            </div>
            <div class="bg__white __economic">
              <div
                v-if="!subRoles.includes('shipment-informmation')"
                style="text-align: center"
                class="justify-content-between align-items-center"
              >
                <v-icon color="black" medium>lock</v-icon>
              </div>
              <div
                class="d-flex justify-content-between align-items-center border-div"
              >
                <label
                  style="margin: 0"
                  class="fs-18 __gray main__align Montserrat-700"
                >
                  <img
                    src="../../assets/shipment.svg"
                    style="margin-right: 7px; margin-top: -3px"
                  />Shipment Information</label
                >
              </div>
              <div class="d-flex justify-content-between">
                <div class="counter">
                  <span>{{ shipmentInformation.week }}</span>
                  <p class="__light">Last week volume</p>
                </div>
                <div class="counter lft__rit__border">
                  <span>{{ shipmentInformation.month }}</span>
                  <p class="__light">Last month volume</p>
                </div>
                <div class="counter">
                  <span>{{ shipmentInformation.thisYearData }}</span>
                  <p class="__light">Year to date</p>
                </div>
              </div>
            </div>
            <div class="bg__white __economic shipment__volume">
              <div
                v-if="!subRoles.includes('shipment-volume')"
                style="text-align: center"
                class="justify-content-between align-items-center"
              >
                <v-icon color="black" medium>lock</v-icon>
              </div>
              <div
                class="d-flex justify-content-between align-items-center border-div"
              >
                <label
                  style="margin: 0"
                  class="fs-18 __gray main__align Montserrat-700"
                >
                  <img
                    src="../../assets/shipment.svg"
                    style="margin-right: 7px; margin-top: -3px"
                  />Shipment Volume</label
                >
                <v-flex
                  v-if="subRoles.includes('shipment-volume') && !overAllFilter"
                  xs8
                  class="wrapper"
                  id="shipmentDate"
                >
                  <ejs-daterangepicker
                    id="shipmentPicker"
                    :placeholder="shipmentMark"
                    v-model="dateRange2"
                    :startDate="startShipmentVal"
                    :endDate="endShipmentVal"
                  ></ejs-daterangepicker>
                </v-flex>

                <!-- <date-range-picker
                  ref="picker"
                  class="pt-0 currencyTitle"
                  :locale-data="{
                    firstDay: 1,
                    Format: 'mm/dd/yyyy',
                    customRangeLabel: 'Custom Range',
                  }"
                  v-model="dateRange"
                  startText="Custom"
                  :ranges="false"
                  :disabled="
                    !subRoles.includes('shipment-volume') || overAllFilter
                  "
                  @update="
                    getShipmentVolume(
                      overAllFilter,
                      startOverallVal,
                      endOverallVal
                    )
                  "
                >
                  <template
                    v-if="dateRange.length"
                    slot="input"
                    slot-scope="picker"
                    style="min-width: 350px"
                    >{{ getTime3(picker.startDate) || "" }} -
                    {{ getTime3(picker.endDate || "") }}</template
                  >
                </date-range-picker> -->
              </div>
              <div class="set__scroll">
                <div class="in__div">
                  <p class="Montserrat-700 fs-16">Last Week</p>
                  <ul class="table__list green__layer __volume-list">
                    <li class="Montserrat-600">
                      <span><img src="../../assets/request.svg" />Request</span
                      ><span
                        v-if="shipmentVolume.week"
                        class="Montserrat-700"
                        >{{ shipmentVolume.week || "0" }}</span
                      >
                      <span v-else class="Montserrat-700">0</span>
                    </li>
                    <li class="Montserrat-600">
                      <span><img src="../../assets/check.svg" />Fulfilled</span
                      ><span class="Montserrat-700 __status__green">{{
                        shipmentVolume.weekFulfilled || "0"
                      }}</span>
                    </li>
                    <li class="Montserrat-600">
                      <span
                        ><img src="../../assets/attention.svg" />Cancelled</span
                      ><span class="Montserrat-700 __status__red">{{
                        shipmentVolume.weekCancelled || "0"
                      }}</span>
                    </li>
                  </ul>
                </div>
                <div class="in__div">
                  <p class="Montserrat-700 fs-16">Last Month</p>
                  <ul class="table__list green__layer __volume-list">
                    <li class="Montserrat-600">
                      <span><img src="../../assets/request.svg" />Request</span
                      ><span
                        v-if="shipmentVolume.month"
                        class="Montserrat-700"
                        >{{ shipmentVolume.month }}</span
                      >
                      <span v-else class="Montserrat-700">0</span>
                    </li>
                    <li class="Montserrat-600">
                      <span><img src="../../assets/check.svg" />Fulfilled</span
                      ><span class="Montserrat-700 __status__green"
                        >{{ shipmentVolume.monthFulfilled || "0" }}
                      </span>
                    </li>
                    <li class="Montserrat-600">
                      <span
                        ><img src="../../assets/attention.svg" />Cancelled</span
                      ><span class="Montserrat-700 __status__red">{{
                        shipmentVolume.monthCancelled || "0"
                      }}</span>
                    </li>
                  </ul>
                </div>
                <div class="in__div">
                  <p class="Montserrat-700 fs-16">Year To date</p>
                  <ul class="table__list green__layer __volume-list">
                    <li class="Montserrat-600">
                      <span><img src="../../assets/request.svg" />Request</span
                      ><span
                        v-if="shipmentVolume.thisYearData"
                        class="Montserrat-700"
                        >{{ shipmentVolume.thisYearData }}</span
                      >
                      <span v-else class="Montserrat-700">0</span>
                    </li>
                    <li class="Montserrat-600">
                      <span><img src="../../assets/check.svg" />Fulfilled</span
                      ><span class="Montserrat-700 __status__green">{{
                        shipmentVolume.yearfulfilled || "0"
                      }}</span>
                    </li>
                    <li class="Montserrat-600">
                      <span
                        ><img src="../../assets/attention.svg" />Cancelled</span
                      ><span class="Montserrat-700 __status__red">{{
                        shipmentVolume.thisYearCancelledData || "0"
                      }}</span>
                    </li>
                  </ul>
                </div>

                <div class="in__div last__in">
                  <p v-if="startDates && endDates" class="Montserrat-700 fs-16">
                    {{ startDates }}-{{ endDates }}
                  </p>
                  <p v-else class="Montserrat-700 fs-16">-</p>

                  <ul class="table__list green__layer __volume-list">
                    <li class="Montserrat-600">
                      <span><img src="../../assets/request.svg" />Request</span
                      ><span
                        v-if="shipmentVolumeFilterData.requested"
                        class="Montserrat-700"
                        >{{ shipmentVolumeFilterData.requested }}</span
                      >
                      <span v-else class="Montserrat-700">0</span>
                    </li>
                    <li class="Montserrat-600">
                      <span><img src="../../assets/check.svg" />Fulfilled</span
                      ><span class="Montserrat-700 __status__green">{{
                        shipmentVolumeFilterData.fulfilled || "0"
                      }}</span>
                    </li>
                    <li class="Montserrat-600">
                      <span
                        ><img src="../../assets/attention.svg" />Cancelled</span
                      ><span class="Montserrat-700 __status__red">{{
                        shipmentVolumeFilterData.cancelled || "0"
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="__evolution">
            <div class="bg__white">
              <div
                v-if="!subRoles.includes('shipment-volume-evolution')"
                style="text-align: center"
                class="justify-content-between align-items-center"
              >
                <v-icon color="black" medium>lock</v-icon>
              </div>
              <v-layout style="justify-content: center">
                <v-btn
                  :class="{ active1: !toggle_exclusive1 }"
                  :disabled="!subRoles.includes('shipment-volume-evolution')"
                  style="text-transform: none !important"
                  @click="
                    toggle_exclusive1 = 0;
                    getShipmentVolumeGraph(
                      overAllFilter,
                      startOverallVal,
                      endOverallVal
                    );
                  "
                  >Local</v-btn
                >
                <v-btn
                  :class="{ active1: toggle_exclusive1 }"
                  :disabled="!subRoles.includes('shipment-volume-evolution')"
                  style="text-transform: none !important"
                  @click="
                    toggle_exclusive1 = 1;
                    getShipmentVolumeGraph(
                      overAllFilter,
                      startOverallVal,
                      endOverallVal
                    );
                  "
                  >Cross border</v-btn
                >
              </v-layout>
              <div class="d-flex justify-content-between align-items-center">
                <label
                  style="margin: 0"
                  class="fs-18 __gray main__align Montserrat-700"
                >
                  <img
                    src="../../assets/shipment.svg"
                    style="margin-right: 7px; margin-top: -3px"
                  />Shipment Volume Evolution</label
                >
                <v-flex xs4>
                  <v-select
                    v-model="shipmentMonth"
                    :items="months"
                    label="Select filter"
                    @change="getShipmentVolumeGraph()"
                    persistent-hint
                    :disabled="
                      !subRoles.includes('shipment-volume-evolution') ||
                      overAllFilter
                    "
                    return-object
                    single-line
                  ></v-select>
                </v-flex>
              </div>
              <div class="chart__content">
                <LineChart
                  :chartdata="chartDataShipment"
                  :options="chartOptionsShipment"
                />
              </div>
            </div>
            <div class="bg__white">
              <div
                v-if="!subRoles.includes('gross-volume-evolution')"
                style="text-align: center"
                class="justify-content-between align-items-center"
              >
                <v-icon color="black" medium>lock</v-icon>
              </div>
              <v-flex style="text-align: center">
                <v-layout style="justify-content: center">
                  <v-btn
                    :class="{ active1: !toggle_exclusive }"
                    :disabled="!subRoles.includes('gross-volume-evolution')"
                    style="text-transform: none !important"
                    @click="
                      toggle_exclusive = 0;
                      getGrossVolumeGraph(
                        overAllFilter,
                        dateRangeOverall2[0],
                        dateRangeOverall2[1]
                      );
                    "
                    >Local</v-btn
                  >
                  <v-btn
                    :class="{ active1: toggle_exclusive }"
                    style="text-transform: none !important"
                    :disabled="!subRoles.includes('gross-volume-evolution')"
                    @click="
                      toggle_exclusive = 1;
                      getGrossVolumeGraph(
                        overAllFilter,
                        startOverallVal,
                        endOverallVal
                      );
                    "
                    >Cross border</v-btn
                  >
                </v-layout>
              </v-flex>
              <div class="d-flex justify-content-between align-items-center">
                <label
                  style="margin: 0"
                  class="fs-18 __gray main__align Montserrat-700"
                >
                  <img
                    src="../../assets/shipment.svg"
                    style="margin-right: 7px; margin-top: -3px"
                  />Gross Volume Evolution</label
                >
                <v-flex xs4>
                  <v-select
                    v-model="grossMonth"
                    :items="months"
                    label="Select filter"
                    persistent-hint
                    :disabled="
                      !subRoles.includes('gross-volume-evolution') ||
                      this.overAllFilter
                    "
                    @change="getGrossVolumeGraph()"
                    return-object
                    single-line
                  ></v-select>
                </v-flex>
              </div>
              <div class="chart__content">
                <LineChart :chartdata="chartData" :options="chartOptions" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--aside__content-->
      <!--card-->
      <v-snackbar
        :timeout="3000"
        bottom
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </div>
    <!--cards__grid-->
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { StorageKeys } from "../../constants/constants";
import { ApiService } from "@/services/api-service";
import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";

Vue.use(DateRangePickerPlugin);
import { Line } from "vue-chartjs";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import redash from "./redash";
import customerDashboard from "./customerDashboard";
import moment from "moment";
import { EventBus } from "../../event-bus.js";
import LineChart from "../Common/LineChart";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";

export default {
  mixins: [checkPermissionsMixin],
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
      if (this.workingCountry.length) {
        this.checkRoles();
        this.getCustomerList();
      }
    }
    EventBus.$on("countryChanged", (val) => {
      this.noTrue = false;
      if (this.$route.name == "analytics") {
        this.workingCountry = val;
        this.customerId = "";
        if (this.currentTab == "customerDashboard") {
          this.checkRoles();
          this.getCustomerList();
        }
      }
    });
  },
  props: {
    currentTab: String,
  },
  components: {
    Loading,
    redash,
    LineChart,
    customerDashboard,
    DateRangePicker,
  },
  watch: {
    dateRangeOverall2() {
      this.getCustomerDetail(this.customerId);
    },
    dateRangeAgeing2(val) {
      if (this.overAllFilter) {
        this.dateRangeAgeing2 = null;
        this.endlVal = null;
        this.startVal = null;
        this.getAgeingAnalysis(
          this.overAllFilter,
          this.startOverallVal,
          this.endOverallVal
        );
      } else {
        this.getAgeingAnalysis(this.overAllFilter, val[0], val[1]);
      }
    },
    dateRange2(val) {
      if (this.overAllFilter) {
        this.dateRange2 = null;
        this.startShipmentVal = null;
        this.endShipmentVal = null;
        this.getShipmentVolume(this.overAllFilter);
      } else {
        this.getShipmentVolume(this.overAllFilter, val[0], val[1]);
      }
    },
  },

  data() {
    return {
      noTrue: false,
      subRoles: [],
      shipmentMark: "Select a Range",
      startShipmentVal: new Date(),
      endShipmentVal: new Date(),
      dateRange2: null,
      waterMark: "Select a Range",
      startVal: new Date(),
      endVal: new Date(),
      startOverallVal: null,
      endOverallVal: null,
      dateRangeAgeing2: null,
      overallMark: "Select a Range",
      dateRangeOverall2: null,
      loading: false,
      toggleClass: false,
      toggle_exclusive: 0,
      toggle_exclusive1: 0,
      CsvUrl: "",
      billingDetails: {},
      ageingAnalysisDetails: {},
      chartData: null,

      chartOptions: {
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: { display: false },
            },
          ],
          yAxes: [
            {
              ticks: {},
            },
          ],
        },
        maintainAspectRatio: false,
      },
      chartDataShipment: null,

      chartOptionsShipment: {
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: { display: false },
            },
          ],
          yAxes: [
            {
              ticks: {},
            },
          ],
        },
        maintainAspectRatio: false,
      },
      driverType: "",
      picker: {},
      startDates: "",
      endDates: "",
      shipmentInformation: {},
      shipmentVolume: {},
      shipmentVolumeFilterData: {},
      keyEconomics: {},
      userDetails: [],
      tradeLicense: "",
      error: null,
      destinations: [],
      dateRange: { startDate: null, endDate: null },
      dateRangeOverall: { startDate: null, endDate: null },
      dateRangeAgeing: { startDate: null, endDate: null },
      months: [
        "Current Month",
        "Last Month",
        "Last 3 Months",
        "Last 6 Months",
        "Lifetime",
      ],
      monthsOverall: [
        "Current Month",
        "Last Month",
        "Last 3 Months",
        "Last 6 Months",
        "Lifetime",
        "Custom",
      ],
      billingMonth: "Current Month",
      overAllFilter: null,
      keyEconomicsMonth: "Current Month",
      grossMonth: "Current Month",
      shipmentMonth: "Current Month",
      majordestn: "Current Month",
      workingCountries: [],

      customerList: [],
      customerId: "",
      isBid: false,
      loading: true,
      currentTab: "redash",
      TabStatus: "redash",
    };
  },

  methods: {
    resetData() {
      this.getCustomerDetail(this.customerId);
      this.billingMonth = "Current Month";
      this.keyEconomicsMonth = "Current Month";
      this.shipmentMonth = "Current Month";
      this.grossMonth = "Current Month";
      this.majordestn = "Current Month";
      (this.toggle_exclusive = 0),
        (this.toggle_exclusive1 = 0),
        (this.overAllFilter = null);
      this.dateRangeOverall.startDate = null;
      this.dateRangeOverall.endDate = null;
      this.dateRangeOverall2 = null;
      this.startVal = new Date();
      this.endVal = new Date();
      this.startShipmentVal = new Date();
      this.endShipmentVal = new Date();
      this.startOverallVal = null;
      this.endOverallVal = null;
    },
    checkRoles() {
      let userPermissions = JSON.parse(
        localStorage.getItem("permissions")
      ).permissions;
      let role = JSON.parse(localStorage.getItem("permissions")).roles;
      userPermissions.forEach((element) => {
        this.subRoles.push(element.operationName);
      });

      role.filter((x) => {
        if (x.role.roleName == "customer-summary-dashboard") {
          if (!x.operations.length) {
            this.noTrue = true;
          }
        }
      });
    },
    download(url, filename) {
      fetch(url).then(function (t) {
        return t.blob().then((b) => {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(b);
          a.setAttribute("download", filename);
          a.click();
        });
      });
    },

    getCustomerList() {
      this.loading = true;
      this.customerList = [];
      let body = {
        skip: 0,
        limit: 15,
        workingCountry: this.workingCountry,
      };
      ApiService.postData("/customerSummary/userList", body).then(
        (response) => {
          this.loading = false;

          response.data.data.list.forEach((element) => {
            this.customerList.push({
              text: element.companyName || element.name || element.email,
              value: element.userId,
            });
          });
          if (this.customerList.length) {
            this.customerId = this.customerList[0].value;
            if (this.customerId) {
              this.getCustomerDetail(this.customerList[0].value);
            }
          }
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getCustomerDetail(id) {
      let allFilterstartDate = null;
      let allFilterendDate = null;

      this.majorDestinations = [];
      if (this.overAllFilter == "Custom" && !this.dateRangeOverall2) {
        return;
      }
      if (this.dateRangeOverall2) {
        if (this.dateRangeOverall2.length) {
          allFilterstartDate = moment(this.dateRangeOverall2[0]).format(
            "DD-MM-YYYY"
          );
          allFilterendDate = moment(this.dateRangeOverall2[1]).format(
            "DD-MM-YYYY"
          );
        }
      }
      if (!this.customerId) {
        this.loading = false;
        this.error = "Please select customer";
        return;
      }
      this.loading = true;
      let body = {
        userId: id,
      };
      ApiService.postData("/customerSummary/userDetails", body).then(
        (response) => {
          this.loading = false;

          this.userDetails = response.data.data.userDetails;
          this.$eventBus.$emit("off-load");
          if (this.subRoles.includes("billing-section")) {
            this.getBillingDetails(
              this.overAllFilter,
              allFilterstartDate,
              allFilterendDate
            );
          }
          if (this.subRoles.includes("key-economics")) {
            this.getKeyEconomics(
              this.overAllFilter,
              allFilterstartDate,
              allFilterendDate
            );
          }
          if (this.subRoles.includes("shipment-informmation")) {
            this.shipmentInformation = response.data.data.shipmentInformation;
          }
          if (this.subRoles.includes("ageing-section")) {
            // this.dateRangeAgeing.startDate = new Date();
            // this.dateRangeAgeing.endDate = new Date();
            this.getAgeingAnalysis(
              this.overAllFilter,
              allFilterstartDate,
              allFilterendDate
            );
            this.getCSV();
          }
          if (this.subRoles.includes("shipment-volume-evolution")) {
            this.getShipmentVolumeGraph(
              this.overAllFilter,
              allFilterstartDate,
              allFilterendDate
            );
          }
          if (this.subRoles.includes("gross-volume-evolution")) {
            this.getGrossVolumeGraph(
              this.overAllFilter,
              allFilterstartDate,
              allFilterendDate
            );
          }
          if (this.subRoles.includes("shipment-volume")) {
            this.shipmentVolume = response.data.data.shipmentVolume;
            this.getShipmentVolume(
              this.overAllFilter,
              allFilterstartDate,
              allFilterendDate
            );
          }
          this.getMajorDestination(
            this.overAllFilter,
            allFilterstartDate,
            allFilterendDate
          );
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    allFilter() {
      this.billingMonth = "";
      this.keyEconomicsMonth = "";
      this.shipmentMonth = "";
      this.grossMonth = "";
      this.majordestn = "h";
      this.dateRangeAgeing.start = "";
      this.getCustomerDetail(this.customerId);
      // this.dateRangeOverall.startDate = new Date();
      // this.dateRangeOverall.endDate = new Date();
    },
    allCustomFilter() {
      this.getCustomerDetail(this.customerId);
    },
    getKeyEconomics(allFilter, allFilterstartDate, allFilterendDate) {
      this.loading = true;
      let body = {
        userId: this.customerId.toString(),
      };
      if (allFilter) {
        body.isFilter = "true";
        body.filter = allFilter;
        if (allFilterstartDate && allFilterendDate) {
          body.startDate = allFilterstartDate;
          body.endDate = allFilterendDate;
        }
      } else {
        body.isFilter = "true";
        body.filter = this.keyEconomicsMonth;
      }
      ApiService.postData("/customerSummary/keyEconomics", body).then(
        (response) => {
          this.loading = false;
          this.keyEconomics = response.data.data;
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getBillingDetails(allFilter, allFilterstartDate, allFilterendDate) {
      this.loading = true;
      let body = {
        userId: this.customerId.toString(),
      };
      if (allFilter) {
        body.isFilter = "true";
        body.filter = allFilter;
        if (allFilterstartDate && allFilterendDate) {
          body.startDate = allFilterstartDate;
          body.endDate = allFilterendDate;
        }
      } else {
        body.isFilter = "true";
        body.filter = this.billingMonth;
      }
      ApiService.postData("/customerSummary/getBillingDetails", body).then(
        (response) => {
          this.loading = false;
          this.billingDetails = response.data.data;
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getShipmentVolumeGraph(allFilter, allFilterstartDate, allFilterendDate) {
      if (this.dateRangeOverall2) {
        if (this.dateRangeOverall2.length) {
          allFilterstartDate = moment(this.dateRangeOverall2[0]).format(
            "DD-MM-YYYY"
          );
          allFilterendDate = moment(this.dateRangeOverall2[1]).format(
            "DD-MM-YYYY"
          );
        }
      }
      let x = {
        labels: [],
        datasets: [
          {
            label: "No. of booking",
            data: [],
            backgroundColor: "transparent",
            borderColor: "rgba(1, 116, 188, 0.50)",
            pointBackgroundColor: "rgba(171, 71, 188, 1)",
          },
        ],
      };
      this.loading = true;
      let body = {
        userId: this.customerId.toString(),
      };
      if (allFilter) {
        body.isFilter = "true";
        body.filter = allFilter;
        if (allFilterstartDate && allFilterendDate) {
          body.startDate = allFilterstartDate;
          body.endDate = allFilterendDate;
        }
      } else if (this.shipmentMonth) {
        body.isFilter = "true";
        body.filter = this.shipmentMonth;
      }
      ApiService.postData("/customerSummary/getShipmentVolumeGraph", body).then(
        (response) => {
          this.loading = false;
          if (!this.toggle_exclusive1) {
            if (response.data.data.localShipments.length) {
              response.data.data.localShipments.forEach((element) => {
                if (
                  this.shipmentMonth == "Current Month" ||
                  this.shipmentMonth == "Last Month"
                ) {
                  let date = moment(element.date).format("Do,MMM,YYYY");

                  x.labels.push(date);
                  x.datasets[0].data.push(element.total);
                } else if (this.shipmentMonth == "Lifetime") {
                  x.labels.push(element.year);
                  x.datasets[0].data.push(element.total);
                } else {
                  let date = moment(element.date).format("MMM,YYYY");
                  x.labels.push(date);
                  x.datasets[0].data.push(element.total);
                }
              });
            }
          } else {
            if (response.data.data.crossBorderShipments.length) {
              response.data.data.crossBorderShipments.forEach((element) => {
                if (
                  this.shipmentMonth == "Current Month" ||
                  this.shipmentMonth == "Last Month"
                ) {
                  let date = moment(element.date).format("Do,MMM,YYYY");

                  x.labels.push(date);
                  x.datasets[0].data.push(element.total);
                  return false;
                } else if (this.shipmentMonth == "Lifetime") {
                  x.labels.push(element.year);
                  x.datasets[0].data.push(element.total);
                } else {
                  let date = moment(element.date).format("MMM,YYYY");
                  x.labels.push(date);
                  x.datasets[0].data.push(element.total);
                }
              });
            }
          }
          this.chartDataShipment = { ...x };
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getGrossVolumeGraph(allFilter, allFilterstartDate, allFilterendDate) {
      if (this.dateRangeOverall2) {
        if (this.dateRangeOverall2.length) {
          allFilterstartDate = moment(this.dateRangeOverall2[0]).format(
            "DD-MM-YYYY"
          );
          allFilterendDate = moment(this.dateRangeOverall2[1]).format(
            "DD-MM-YYYY"
          );
        }
      }
      let x = {
        labels: [],
        datasets: [
          {
            label: "gross margin in %",
            data: [],
            backgroundColor: "transparent",
            borderColor: "rgba(1, 116, 188, 0.50)",
            pointBackgroundColor: "rgba(171, 71, 188, 1)",
          },
        ],
      };
      this.loading = true;
      let body = {
        userId: this.customerId.toString(),
      };
      if (allFilter) {
        body.isFilter = "true";
        body.filter = allFilter;
        if (allFilterstartDate && allFilterendDate) {
          body.startDate = allFilterstartDate;
          body.endDate = allFilterendDate;
        }
      } else if (this.grossMonth) {
        body.isFilter = "true";
        body.filter = this.grossMonth;
      }
      ApiService.postData("/customerSummary/getGrossVolumeGraph", body).then(
        (response) => {
          this.loading = false;

          if (!this.toggle_exclusive) {
            if (response.data.data.localShipments.length) {
              response.data.data.localShipments.forEach((element) => {
                if (
                  this.grossMonth == "Current Month" ||
                  this.grossMonth == "Last Month"
                ) {
                  let date = moment(element.date).format("Do,MMM,YYYY");

                  x.labels.push(date);
                  x.datasets[0].data.push(Math.round(element.percentage));
                } else if (this.grossMonth == "Lifetime") {
                  x.labels.push(element.year);
                  x.datasets[0].data.push(Math.round(element.percentage));
                } else {
                  let date = moment(element.date).format("MMM,YYYY");

                  x.labels.push(date);
                  x.datasets[0].data.push(Math.round(element.percentage));
                }
              });
            }
          } else {
            if (response.data.data.crossBorderShipments.length) {
              response.data.data.crossBorderShipments.forEach((element) => {
                if (
                  this.grossMonth == "Current Month" ||
                  this.grossMonth == "Last Month"
                ) {
                  let date = moment(element.date).format("Do,MMM,YYYY");

                  x.labels.push(date);
                  x.datasets[0].data.push(Math.round(element.percentage));
                } else if (this.grossMonth == "Lifetime") {
                  x.labels.push(element.year);
                  x.datasets[0].data.push(Math.round(element.percentage));
                } else {
                  let date = moment(element.date).format("MMM,YYYY");

                  x.labels.push(date);
                  x.datasets[0].data.push(Math.round(element.percentage));
                }
              });
            }
          }

          this.chartData = { ...x };
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getShipmentVolume(allFilter, allFilterstartDate, allFilterendDate) {
      if (this.dateRangeOverall2) {
        if (this.dateRangeOverall2.length) {
          allFilterstartDate = moment(this.dateRangeOverall2[0]).format(
            "DD-MM-YYYY"
          );
          allFilterendDate = moment(this.dateRangeOverall2[1]).format(
            "DD-MM-YYYY"
          );
        }
      }
      this.loading = true;
      let body = {
        userId: this.customerId.toString(),
      };
      if (allFilter) {
        // this.dateRange.startDate = null;
        // this.dateRange.endDate = null;
        this.dateRange2 = null;
        // this.startShipmentVal=null;
        // this.endShipmnetVal=null;
        this.startDates = "";
        this.endDates = "";
        body.isFilter = "true";
        body.filter = allFilter;
        if (allFilterstartDate && allFilterendDate) {
          body.startDate = allFilterstartDate;
          body.endDate = allFilterendDate;
        }
      } else {
        if (!this.dateRange2) {
          this.startShipmentVal = new Date();
          this.endShipmentVal = new Date();
          body.startDate = moment(this.startShipmentVal).format("DD-MM-YYYY");
          body.endDate = moment(this.endShipmentVal).format("DD-MM-YYYY");
        }

        if (this.dateRange2) {
          this.startDates = moment(this.dateRange2[0]).format("DD-MM-YYYY");
          this.endDates = moment(this.dateRange2[1]).format("DD-MM-YYYY");
          body.startDate = moment(this.dateRange2[0]).format("DD-MM-YYYY");
          body.endDate = moment(this.dateRange2[1]).format("DD-MM-YYYY");
        }
      }

      ApiService.postData("/customerSummary/filteredShipmentVolume", body).then(
        (response) => {
          this.loading = false;
          this.shipmentVolumeFilterData =
            response.data.data.filteredShipmentVolume;
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getMajorDestination(allFilter, allFilterstartDate, allFilterendDate) {
      this.destinations = [];
      this.loading = true;
      let body = {
        userId: this.customerId.toString(),
      };
      if (allFilter) {
        body.isFilter = "true";
        body.filter = allFilter;
        if (allFilterstartDate && allFilterendDate) {
          body.startDate = allFilterstartDate;
          body.endDate = allFilterendDate;
        }
      } else {
        body.isFilter = "true";
        body.filter = this.majordestn;
      }
      ApiService.postData("/customerSummary/getMajorDestinations", body).then(
        (response) => {
          this.loading = false;
          this.destinations = response.data.data.majorDestinations;
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getCSV() {
      this.loading = true;
      ApiService.postData("/customerSummary/createBillReportCSV", {
        userId: this.customerId.toString(),
      }).then(
        (response) => {
          this.loading = false;
          if (response.data.data) {
            this.CsvUrl = response.data.data.url;
          }
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getAgeingAnalysis(allFilter, allFilterstartDate, allFilterendDate) {
      this.destinations = [];
      this.loading = true;
      let body = {
        userId: this.customerId.toString(),
      };
      if (allFilter) {
        // this.dateRangeAgeing.startDate = null;
        // this.dateRangeAgeing.endDate = null;
        this.dateRangeAgeing2 = null;
        body.filter = allFilter;
        body.isFilter = "true";
        if (allFilterstartDate && allFilterendDate) {
          body.startDate = allFilterstartDate;
          body.endDate = allFilterendDate;
        }
      } else {
        if (!this.dateRangeAgeing2) {
          this.startVal = new Date();
          this.endVal = new Date();
          body.startDate = moment(this.startVal).format("DD-MM-YYYY");
          body.endDate = moment(this.endVal).format("DD-MM-YYYY");
        } else {
          body.startDate = moment(this.startVal).format("DD-MM-YYYY");
          body.endDate = moment(this.endVal).format("DD-MM-YYYY");
        }
      }
      ApiService.postData("/customerSummary/getAggingDetails", body).then(
        (response) => {
          this.loading = false;
          this.ageingAnalysisDetails = response.data.data;
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getTime(time) {
      return moment(time).format("MMM,Do,YYYY");
    },
    getTime3(date) {
      return moment.utc(date).format("Do MMM  YYYY");
    },
  },
};
</script>
<style lang="scss">
div#overallPicker_popup {
  left: 43.2px !important;
}
#overallDate .e-input-group-icon.e-range-icon.e-icons::after {
  content: none !important;
}

#overallDate span.e-input-group-icon.e-range-icon.e-icons {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  text-align: right;
}
#overallDate .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: "\e245";
  font-family: "e-icons";
  float: right;
  position: absolute;
  right: -5px !important;
}
#overallDate .e-daterangepicker {
  left: inherit !important;
  right: 120px;
}

#overallDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
div#ageingPicker_popup {
  left: 384.2px !important;
}
#ageingDate .e-input-group-icon.e-range-icon.e-icons::after {
  content: none !important;
}

#ageingDate span.e-input-group-icon.e-range-icon.e-icons {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  text-align: right;
}
#ageingDate .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: "\e245";
  font-family: "e-icons";
  float: right;
  position: absolute;
  right: 0;
}
#ageingDate .e-daterangepicker {
  left: inherit !important;
  right: 120px;
}
.e-daterangepicker.e-popup .e-calendar,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar {
  background-color: #fff;
  height: 100%;
}
#ageingDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
div#shipmentPicker_popup {
  left: 658.2px !important;
}
#shipmentDate .e-input-group-icon.e-range-icon.e-icons::after {
  content: none !important;
}

#shipmentDate span.e-input-group-icon.e-range-icon.e-icons {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  text-align: right;
}
#shipmentDate .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: "\e245";
  font-family: "e-icons";
  float: right;
  position: absolute;
  right: -5px !important;
}
#shipmentDate .e-daterangepicker {
  left: inherit !important;
  right: 120px;
}

#shipmentDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
.daterangepicker.opensright[data-v-267f4ee2] {
  top: 28px;
}
.daterangepicker.openscenter[data-v-267f4ee2] {
  position: absolute;
  top: 35px;
  left: 80%;
}
.daterangepicker.openscenter {
  right: auto;
  left: 0 !important;
}
.reportrange-text[data-v-267f4ee2] {
  background: #fff;
  cursor: pointer;
  position: relative !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  width: 200px !important;
  float: right;
}
.active1 {
  background-color: orange !important;
  color: #ffffff !important;
}

.drp-calendar {
  margin-right: 80px !important;
}

.daterangepicker.openscenter[data-v-267f4ee2] {
  right: auto;
  left: 62%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
</style>

<style scoped>
body {
  padding: 20px;
  background-color: #f5f5f5;
}
.text__right {
  text-align: end !important;
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.content {
  background: #fff;
  border-radius: 7px;
  box-shadow: 0px 0px 25px rgb(0 0 0 / 22%);
}
.__full {
  grid-column-start: 1;
  grid-column-end: 3;
}
.__gray {
  color: #333;
}
.__orange {
  color: #f58618;
}
.gray__dark {
  color: #797979;
}
.__light {
  color: #999999;
}
.__red {
  color: #ff4b55;
}
.cards__grid {
  display: grid;
  grid-gap: 0;
  width: 100%;
  grid-template-columns: 290px 1fr;
}
.card__head {
  padding: 10;
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #e1e1e1;
}
.__aside {
  padding: 20px;
}
.aside__content {
  background: #f5f5f5;
}

.profile-in {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 6px;
  margin-bottom: 20px;
}
.profile-in label {
  line-height: 18px;
  margin-right: 10px;
}
.profile-in img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.inner__aside {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
.credit__details {
  background-color: #202020;
  padding: 20px;
  color: #fff;
  margin-top: 20px;
  border-radius: 8px;
}
.credit__details p {
  display: inline-flex;
  justify-content: space-between;
  border-bottom: 1.4px solid #4d4d4d;
  width: 100%;
  margin-top: 12px;
  padding-bottom: 10px;
}
.download {
  border: 1.5px dashed orange;
  border-radius: 5px;
  margin-top: 15px;
}

.download .__text {
  padding: 15px;
}
.download__btn {
  padding: 20px;
  background: #fff2e9;
  margin: 0px;
}
.top__grid {
  display: grid;
  grid-template-columns: 350px 1fr;
  margin-bottom: 25px;
}
.__billing {
  border-radius: 6px;
  padding: 15px 20px;
  box-shadow: 1px 1px 10px #00000014;
  grid-row-start: 1;
  grid-row-end: 3;
}
.bg__white {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
}
.card__order {
  padding: 20px;
  border-radius: 8px;
}
.table__list li:nth-child(2n + 1) {
  background: #fef0de;
}
ul.table__list {
  margin-top: 20px;
  padding: 0px;
}
ul.table__list li {
  padding: 15px;
  border-radius: 6px;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
ul.table__list li span {
  padding-left: 10px;
  border-left: 1.4px solid #cbc0b28c;
}
select {
  outline: none;
  border: none;
  color: #f47824;
}
select option {
  color: #333;
}

/* .__economic{
    grid-row-end: 2;
    grid-row-start: 1;
    margin-bottom: 30px;
} */
.__economic {
  margin-left: 20px;
  margin-bottom: 20px;
}
ul.economy__card li {
  border-bottom: 1.4px solid #e5e5e5;
  padding: 9px 0px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
ul.economy__card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  font-size: 12px;
  margin-top: 17px;
  padding-left: 0px !important;
}
ul.economy__card img {
  float: left;
  margin-right: 9px;
  margin-top: -4px;
}
ul.economy__card li span:nth-last-child(1) {
  padding-left: 10px;
  border-left: 1.4px solid #cbc0b28c;
  white-space: nowrap;
  margin-left: 10px;
}
.__destination {
  margin-left: 20px;
  background-image: url(../../assets/roadmap.svg);
  display: grid;
}
ul.location__tag {
  /* display: grid; */
  white-space: nowrap;
  overflow: scroll;
  width: 100%;
  writing-mode: horizontal-tb;
  overflow-y: hidden;
  margin-top: 21px;
  padding-left: 0px !important;
  padding-bottom: 9px;
}
ul.location__tag li {
  display: inline-block;
  background: #202020;
  padding: 10px 15px;
  color: #fff;
  margin-left: 10px !important;
  border-radius: 50px;
  font-size: 12px;
}
.location__tag::-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}
.location__tag::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}
.location__tag::-webkit-scrollbar-thumb {
  background-color: #df4f1b;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
}
.__analysis {
  grid-row-start: 1;
  grid-row-end: 3;
}
.__analysis .__text {
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 20px;
}
.__analysis .download {
  margin-bottom: 20px;
}
.__analysis .__text p {
  font-size: 12px;
  margin-bottom: 6px !important;
}
.__analysis .__text span {
  font-size: 14px;
  margin-top: -6px;
}
.green__layer li:nth-child(2n + 1) {
  background: #dfebdb;
}
.green__layer li span:nth-last-child(2) {
  border-left: 0px;
  padding: 0;
  display: flex;
  align-items: center;
}

.green__layer li img {
  margin-right: 10px;
}
.green__layer li label {
  display: inline-block;
}
.border-div {
  margin-bottom: 20px;
  border-bottom: 1.3px solid #d8d8d8;
  padding-bottom: 15px;
}
.counter span {
  font-size: 30px;
  color: #333;
  font-weight: bold;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.lft__rit__border {
  border-left: 1.3px solid #d8d8d8;
  border-right: 1.3px solid #d8d8d8;
}
.shipment__volume {
  margin-bottom: 0;
}

.__evolution {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.chart__content img {
  width: 100%;
}
.counter.lft__rit__border {
  margin: 0px 20px;
  padding-left: 20px;
}
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.shipment__volume {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 0;
}
.shipment__volume .border-div {
  grid-column-start: 1;
  grid-column-end: 5;
}

ul.table__list.__volume-list li {
  padding: 0;
  background: transparent;
  margin-bottom: 14px;
  white-space: nowrap;
}
.__evolution {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.chart__content img {
  width: 100%;
}
.__status__green {
  color: #73af00;
}
.__status__red {
  color: #ff4b55;
}
.set__scroll {
  display: grid;
  writing-mode: horizontal-tb;
  overflow: auto;
  width: 100%;
  overflow-y: hidden;
  grid-template-columns: auto auto auto auto;
  grid-column-start: 1;
  grid-column-end: 3;
}

.set__scroll li span:nth-last-child(1) {
  width: 50px;
  margin-left: 10px;
}

ul.__volume-list {
  border-top: 1.4px dashed rgba(0, 0, 0, 0.37);
  padding: 10px 18px;
  border-right: 1.4px dashed rgba(0, 0, 0, 0.37);
  padding-right: 0;
}
.set__scroll::-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}
.set__scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}
.set__scroll::-webkit-scrollbar-thumb {
  background-color: #df4f1b;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
}
.main__align {
  display: flex;
  align-items: center;
}
</style>
