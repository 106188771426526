<template>
  <v-card class>
    <loading :active.sync="loading" />

    <v-layout> </v-layout>

    <v-container fluid>
      <!-- <v-layout row class="pb-2">
        <v-flex xs3 pl-1 pt-3 class="wrapper" id="jobDate">
          <ejs-daterangepicker
            :placeholder="waterMark"
            v-model="dateRange2"
          ></ejs-daterangepicker>
        </v-flex>

        <v-flex xs1 class="reset-btn reset__btn">
          <v-card-actions class="justify-center">
            <v-btn
              flat
              class="reset-btn-color"
              prepend-icon
              color="orange darken-1"
              @click.native="reset()"
            >
              Reset
              <v-icon color="orange">donut_large</v-icon>
            </v-btn>
          </v-card-actions>
        </v-flex>
        <v-flex xs1 class="reset-btn download__btn" v-if="dateRange2">
          <v-card-actions class="justify-center">
            <v-btn
              flat
              class="reset-btn-color"
              prepend-icon
              color="orange darken-1"
              @click.native="download()"
            >
              Download
              <v-icon color="orange">download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-flex>
      </v-layout> -->
    </v-container>
    <v-card-text class="nospace" pt-1>
      <v-data-table
        id="jobsTable"
        class="word___Break"
        hide-actions
        :disable-initial-sort="true"
        :headers="headers"
        :items="items"
      >
        <template slot="items" slot-scope="props">
          <td>
            <v-layout style="width:60px">{{ props.item.jobId }}</v-layout>
          </td>
          <td>
            <v-layout>{{ props.item.jobMode }}</v-layout>
          </td>
          <td>
            <v-layout> {{ props.item.jobType }}</v-layout>
          </td>

          <td>
            <v-layout justify-center>{{ props.item.customer }} </v-layout>
          </td>

          <td v-if="props.item.customerAcceptedRate.$numberDecimal">
            {{
              convertToDecimal(
                props.item.customerAcceptedRate["$numberDecimal"]
              )
            }}
            {{ props.item.customerCurrency }}
          </td>
          <td v-else>
            N.A
          </td>
          <td
            class="address_td"
            style="width: 40%; word-break: break-all;min-width:8rem"
          >
            <v-tooltip
              top
              class="milestone"
              content-class="tooltip-op"
              color="orange"
            >
              <template slot="activator"
                >{{ props.item.sourceCity }},
                {{ props.item.sourceCountry }}</template
              >
              <span class="tooltip"
                >{{ props.item.sourceCity }},{{
                  props.item.sourceCountry
                }}</span
              >
            </v-tooltip>
          </td>
          <td
            class="address_td"
            style="width: 10%; word-break: break-all;min-width:8rem"
          >
            <v-tooltip
              top
              class="milestone"
              content-class="tooltip-op"
              color="orange"
            >
              <template slot="activator"
                >{{ props.item.destinationCity }},
                {{ props.item.destinationCountry }}</template
              >
              <span class="tooltip"
                >{{ props.item.destinationCity }},
                {{ props.item.destinationCountry }}</span
              >
            </v-tooltip>
          </td>
          <td>
            <v-layout style="width:70px">{{
              props.item.assignmentId || "N.A"
            }}</v-layout>
          </td>
          <td>
            <v-layout style="width:80px"
              >{{ props.item.status || "N.A" }}
            </v-layout>
          </td>
          <td>
            <v-layout>{{ props.item.office || "N.A" }}</v-layout>
          </td>
          <td>
            <v-layout>{{
              getTime4(props.item.offloadedDate) || "N.A"
            }}</v-layout>
          </td>
          <td>
            <v-layout>{{
              getTime4(props.item.completeDate) || "N.A"
            }}</v-layout>
          </td>
          <td>
            <v-layout>
              Id: {{ props.item.driverId }}
              {{ props.item.driverName }}
              <br />
              {{ props.item.driverCountryCode }}-{{
                props.item.driverPhoneNo
              }}</v-layout
            >
          </td>
          <td>
            <v-layout>{{ props.item.vehicleNumber || "N.A" }}</v-layout>
          </td>

          <td>
            <v-layout>{{ props.item.ownerType || "N.A" }}</v-layout>
          </td>
          <td>
            <v-layout justify-center>{{
              props.item.saleExecutive || "N.A"
            }}</v-layout>
          </td>
          <td v-if="props.item.salesInvoiceGenratedAt">
            <v-layout justify-center>{{
              getTime4(props.item.salesInvoiceGenratedAt)
            }}</v-layout>
          </td>
          <td v-else>
            <v-layout justify-center>N.A</v-layout>
          </td>
          <td>
            <v-layout
              >{{ props.item["purchaseInvoice No"] || "N.A" }}<br />
              {{ getTime4(props.item.purchaseInvoiceGeneratedAt) }}
            </v-layout>
          </td>

          <td v-if="props.item.driverAcceptedRate.$numberDecimal">
            <v-layout
              >{{
                convertToDecimal(
                  props.item.driverAcceptedRate["$numberDecimal"]
                )
              }}
              {{ props.item.customerCurrency }}</v-layout
            >
          </td>
          <td v-else>
            N.A
          </td>
          <td v-if="props.item.additionalCharge.$numberDecimal">
            <v-layout
              >{{
                convertToDecimal(props.item.additionalCharge["$numberDecimal"])
              }}
              {{ props.item.customerCurrency }}</v-layout
            >
          </td>
          <td v-else>
            N.A
          </td>
          <td v-if="props.item.advanceAmountRequested.$numberDecimal">
            <v-layout
              >{{
                convertToDecimal(
                  props.item.advanceAmountRequested["$numberDecimal"]
                )
              }}
              {{ props.item.customerCurrency }}</v-layout
            >
          </td>
          <td v-else>
            N.A
          </td>
          <td v-if="props.item.advanceAmountPaid.$numberDecimal">
            <v-layout
              >{{
                convertToDecimal(props.item.advanceAmountPaid["$numberDecimal"])
              }}
              {{ props.item.customerCurrency }}</v-layout
            >
          </td>
          <td v-else>
            N.A
          </td>
          <td v-if="props.item.advanceAmountPaidDate">
            <v-layout>{{
              getTime4(props.item.advanceAmountPaidDate)
            }}</v-layout>
          </td>
          <td v-else>
            N.A
          </td>

          <td v-if="props.item.VAT">
            <v-layout
              >{{ convertToDecimal(props.item.VAT) }}
              {{ props.item.customerCurrency }}</v-layout
            >
          </td>
          <td v-else>
            N.A
          </td>
          <td v-if="props.item.totalPurchases.$numberDecimal">
            <v-layout
              >{{
                convertToDecimal(props.item.totalPurchases["$numberDecimal"])
              }}
              {{ props.item.customerCurrency }}</v-layout
            >
          </td>
          <td v-else>
            N.A
          </td>

          <td v-if="props.item.pendingPayment.$numberDecimal">
            <v-layout
              >{{
                convertToDecimal(props.item.pendingPayment["$numberDecimal"])
              }}
              {{ props.item.customerCurrency }}</v-layout
            >
          </td>
          <td v-else>
            N.A
          </td>
        </template>
        <v-alert
          slot="no-results"
          :value="isNoSearchResult"
          color="blue"
          icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
      <pagination
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :componentKey="componentKey"
        :type="0"
        :url="paginationURL"
        :callback="callback"
        :action="`purchaseReport`"
        v-on:pagination-load="checkLoading"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import moment from "moment";
  let momentTz = require("moment-timezone");
  import { purchaseReport } from "@/constants/datatable-headers.js";
  import pagination from "@/components/Pagination/pagination";
  import Vue from "vue";
  import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
  import { StorageKeys } from "../../constants/constants";

  Vue.use(DateRangePickerPlugin);
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import Loading from "vue-loading-overlay";
  import { EventBus } from "../../event-bus.js";
  import downloadMixin from "@/mixins/downloadMixin";
  export default {
    mixins: [downloadMixin],
    created() {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });

        this.workingCountry = JSON.stringify(workingCountry);
        this.paginationURL = `/dmsAdmin/purchaseReport?workingCountry=${this.workingCountry}`;
        this.loading = false;

        // this.getListCount();
      }

      EventBus.$on("countryChanged", (val) => {
        this.workingCountry = JSON.stringify(val);
        if (this.reportType == "Purchase Report") {
          this.paginationURL = `/dmsAdmin/purchaseReport?workingCountry=${this.workingCountry}`;
          this.componentKey = this.componentKey + 1;
        }
      });
    },
    components: {
      pagination,
      Loading,
    },
    props: {
      reportType: String,
      dateRangeFilter: Array,
      reset: String,
      download: Boolean,
    },
    watch: {
      dateRangeFilter(val) {
        if (this.reportType == "Purchase Report") {
          if (val) {
            if (val[0] && val[1]) {
              this.startDate = moment(val[0]).format("YYYY-MM-DD");
              this.endDate = moment(val[1]).format("YYYY-MM-DD");
            }
            this.searchData(null, val);
          } else {
            this.startDate = "";
            this.endDate = "";

            this.searchData(null, val);
          }
        }
      },
      reset(val) {
        if (this.reportType == "Purchase Report") {
          this.paginationURL = `/dmsAdmin/purchaseReport?workingCountry=${this.workingCountry}`;
        }
      },
      download(val) {
        if (this.reportType == "Purchase Report") {
          this.downloadFile();
        }
      },
    },
    data() {
      return {
        // ----- NOTIFICATION ---

        dateRange2: null,
        startDate: "",
        endDate: "",
        loading: true,
        headers: purchaseReport,
        items: [],
        pagination: {},
        totalPages: 0,
        isNoSearchResult: false,
        paginationURL: "",
        pages: 1,
        search: "",
        componentKey: 0,
      };
    },
    methods: {
      convertToDecimal(value) {
        let number = parseFloat(value);

        return Number.isInteger(number) ? number : number.toFixed(2);
      },
      // reset() {
      //   this.dateRange2 = null;
      // },
      downloadFile() {
        let url = [];
        let timezone = momentTz.tz.guess();
        const date = new Date();
        url.push(`workingCountry=${this.workingCountry}`);
        url.push(`offset=${date.getTimezoneOffset()}`);

        url.push(`startDate=${this.startDate}`);
        url.push(`endDate=${this.endDate}`);
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let finalUrl = "/dmsAdmin/downloadPoReport?";
        if (url.length > 0) {
          let params = url.join("&");
          finalUrl = finalUrl + params;
        } else return "";
        let fileName = `purchase_report_${this.startDate}_to_${this.endDate}`;
        this.DownloadExcel(this.constants.apiUrl + finalUrl, fileName);
      },

      searchData(jobStatusVal = null, dateRangeVal = null) {
        //  this.getsearchTypeList(jobStatusVal);
        if (!navigator.onLine) {
          this.loading = false;
          this.x.error = "Please check your internet connection";
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          return;
        }

        let url = [];
        url.push(`workingCountry=${this.workingCountry}`);

        if (dateRangeVal) {
          url.push(`startDate=${this.startDate}`);
          url.push(`endDate=${this.endDate}`);
        } else if (this.startDate.length > 0) {
          url.push(`startDate=${this.startDate}`);
          url.push(`endDate=${this.endDate}`);
        }

        let finalUrl = "/dmsAdmin/purchaseReport?";
        if (url.length > 0) {
          let params = url.join("&");
          finalUrl = finalUrl + params;
        } else return "";

        // this.loading = true;
        this.paginationURL = finalUrl;
      },

      getTime4(date) {
        return moment(date).format("ll HH:mm");
      },

      checkLoading(event) {
        if (event) {
          this.loading = true;
        } else {
          this.loading = false;
        }
      },
      page(event) {
        this.pages = event;
      },
      async callback({ list, totalData }) {
        this.items = list;
        this.pagination.rowsPerPage = 15;
        // this.getListCount();
        this.totalPages = Math.ceil(totalData / 15);

        this.$emit("job-load", false);
        window.scrollTo(0, 0);
        this.loading = false;
      },
    },
  };
</script>
<style lang="scss">
  .card--raised {
    border: 1px solid orange !important;
  }
  #filter_data_toggle .flex.xs2.pr-2 > div {
    padding-top: 0 !important;
  }
  .divider-style {
    margin-bottom: 24px;
    margin-top: 15px;
    background: lightgrey;
  }
  .reset__btn {
    position: absolute;
    right: 0;
  }
  .download__btn {
    position: absolute;
    margin-right: 127px;
    right: 0;
  }
  .gap {
    position: relative;
    right: 2px;

    z-index: auto;
  }
  // .date_9 input#ej2-datetimepicker_0 {
  //   font-size: 16px;
  // }
  .searchAccounts .v-label.v-label--is-disabled.theme--light {
    left: 10px !important;
  }
  .tooltip {
    font-size: 13px;
    width: auto !important;
    font-weight: 500;
  }
  .tooltip-op {
    opacity: 1 !important;
  }
  .searchAccounts .theme--light.v-label {
    left: 10px !important;
  }
  .searchAccounts input {
    padding-left: 10px !important;
  }

  .table-header-item1 {
    font-size: 16px !important;
    color: orange;
    font-weight: bold;
  }

  td {
    font-size: 16px !important;
  }

  thead {
    background: #fbfbfb;
  }
</style>

<style scoped>
  #filter-toggle {
    display: none;
  }
  .pd-left {
    padding-left: 5px !important;
  }
  .address_td {
    width: 10%;
    word-break: break-all;
  }
  .pd-t-l {
    padding-left: 5px;
    padding-top: 7px;
  }
  .track-ass-btn {
    text-decoration: none;
    margin: 7px !important;
    color: #ffffff;
    background-color: #ffc278;
    border-radius: 5px;
    border: 2px solid darkorange;
    padding: 6px 15px;
  }
  .total.avatar .count {
    background-color: darkorange;
    color: white !important;
    padding: 0 5px;
    font-size: 18px !important;
    border-radius: 5px;
    margin-left: 10px;
  }
  .total.avatar {
    min-width: 280px !important;
    height: 50px !important;
    /* background-color: #ff8c002b !important; */
    /* border-bottom: 3px solid darkorange !important; */
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border-radius: 0;
  }
  .total.avatar span {
    color: darkorange !important;
  }

  .status-count {
    font-size: 16px;

    font-weight: 900 !important;
  }
  .reset-btn {
    float: right;
    margin-top: 6px;
  }
  .avatar {
    border-radius: 10px;
  }
  .viewDetail {
    cursor: pointer;
  }
  .job-detail {
    margin: auto 0;
  }
  .theme--light.menuable__content__active {
    min-width: 290px !important;
  }
  .tooltip {
    font-size: 13px;
    width: auto !important;
    font-weight: 500;
  }
  .tooltip-op {
    opacity: 1 !important;
  }
  .text-center {
    text-align: center !important;
  }

  .date-picker {
    min-width: 100%;
  }

  .page-count-span {
    float: right;
    margin-top: 26px;
    color: darkorange;
  }
  .track-ass-btn {
    text-decoration: none;
    color: #ffffff;
    background-color: #ffc278;
    border-radius: 5px;
    border: 2px solid darkorange;
    padding: 2px 5px;
  }

  .cancel-btn {
    text-decoration: none;
    color: #ffffff;
    border: 2px solid red;
    padding: 2px;
    margin-top: 5px;
    background-color: #f38282;
    border-radius: 5px;
  }
  .view-ass-btn {
    text-decoration: none;
    color: #ffffff;
    background-color: #c87ffe;
    border: 2px solid indigo;
    padding: 2px 5px;
    border-radius: 5px;
  }
  .assign-driver-btn {
    text-decoration: none;
    color: #ffffff;
    background-color: #bebebe;
    border: 2px solid grey;
    padding: 2px 5px;
    border-radius: 5px;
  }
  .reset-btn-color {
    background-color: #ffe7c9 !important;
  }
  .p-16 {
    padding: 0 16px !important;
  }
  .p-b-0 {
    padding-bottom: 0 !important;
  }
  .p-t-0 {
    padding-top: 0 !important;
  }
  .minHeight {
    min-height: 300px;
  }
  .colorchange {
    background-color: #fae3e3 !important;
  }
  .colorchangeyellow {
    background-color: #ffffe0 !important;
  }
  .spacerclass {
    max-height: 550px !important;
  }
  .m-t-10 {
    margin-top: 10px;
  }

  .filter {
    padding: 20px;
    margin: 0 auto;
  }
  .reset {
    margin-top: 10px;
  }
  .gap {
    position: relative;
    right: 2px;

    z-index: auto;
  }
  .reset-btn-color {
    background-color: #ffe7c9 !important;
  }
  .word___Break {
    word-break: break-word !important;
  }
  .button-red {
    background-color: #ff7b7b !important;
    color: #ffffff !important;
    border: 2px solid red !important;
    margin-top: 5px !important;
    margin-left: 25px !important;
  }
</style>
