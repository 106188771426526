var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showScreen)?_c('v-form',{ref:"form",attrs:{"lazy-validation":_vm.lazy},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticStyle:{"max-width":"100% !important"}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":"","color":"yellow darken-2"}}),_c('v-card',{attrs:{"color":"white"}},[_c('v-card-title',{staticClass:"bg-clr",attrs:{"primary-title":""}},[_c('v-btn',{staticClass:"nospace",attrs:{"fab":"","flat":"","icon":""},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_c('v-icon',[_vm._v("arrow_back")])],1),_c('span',{staticClass:"headline"},[_vm._v(" Qlik Sense")])],1),_c('v-card-text',[(_vm.noTrue)?_c('v-layout',{staticClass:"errorbox"},[_c('v-flex',[_vm._v("Seems like you don't have any permissions. Please contact administrator")])],1):_vm._e(),_c('v-layout',{staticClass:"scrollFix",staticStyle:{"overflow-x":"auto"},attrs:{"pt-2":""}},[(_vm.workingCountry.indexOf('UAE') >= 0)?_c('v-btn',{directives:[{name:"permissions",rawName:"v-permissions",value:('uae-dashboard'),expression:"'uae-dashboard'"}],staticClass:"btnStyle",attrs:{"round":"","color":"orange","dark":""},on:{"click":function($event){return _vm.redirect(
                _vm.links.UaeSalesDashboard,
                'uae-dashboard',
                'Inquiry Details'
              )}}},[_vm._v("UAE Sales dashboard")]):_vm._e(),(_vm.workingCountry.indexOf('PAK') >= 0)?_c('v-btn',{directives:[{name:"permissions",rawName:"v-permissions",value:('pak-dashboard'),expression:"'pak-dashboard'"}],staticClass:"btnStyle",attrs:{"round":"","color":"orange","dark":""},on:{"click":function($event){return _vm.redirect(
                _vm.links.PakSalesDashboard,
                'pak-dashboard',
                'Inquiry Details'
              )}}},[_vm._v("PAK Sales dashboard")]):_vm._e(),(_vm.workingCountry.indexOf('KSA') >= 0)?_c('v-btn',{directives:[{name:"permissions",rawName:"v-permissions",value:('ksa-dashboard'),expression:"'ksa-dashboard'"}],staticClass:"btnStyle",attrs:{"round":"","color":"orange","dark":""},on:{"click":function($event){return _vm.redirect(
                _vm.links.KsaSalesDashboard,
                'ksa-dashboard',
                'Inquiry Details'
              )}}},[_vm._v("KSA Sales dashboard")]):_vm._e(),_c('v-btn',{directives:[{name:"permissions",rawName:"v-permissions",value:('pod-dashboard'),expression:"'pod-dashboard'"}],staticClass:"btnStyle",attrs:{"round":"","color":"orange","dark":""},on:{"click":function($event){return _vm.redirect(_vm.links.PodDashboard, 'pod-dashboard', 'Inquiry Details')}}},[_vm._v("POD Dashboard")]),(
              _vm.workingCountry.indexOf('UAE') >= 0 ||
              _vm.workingCountry.indexOf('KSA') >= 0
            )?_c('v-btn',{directives:[{name:"permissions",rawName:"v-permissions",value:('complete-assignment-level-report'),expression:"'complete-assignment-level-report'"}],staticClass:"btnStyle",attrs:{"round":"","color":"orange","dark":""},on:{"click":function($event){return _vm.redirect(
                _vm.links.completeAssignmentLevelReport,
                'complete-assignment-level-report',
                'Inquiry Details'
              )}}},[_vm._v("Complete assignment level report")]):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","pt-4":""}},[(_vm.link)?_c('iframe',{staticStyle:{"height":"1000px","width":"100%"},attrs:{"target":"_blank","name":"frame","src":this.link}}):_vm._e()])],1)],1),_c('success-dialog',{attrs:{"content":"Customer Edited successful","show":_vm.x.registerSuccess,"onclose":_vm.closeRegistration}}),(_vm.x.error)?_c('v-snackbar',{staticClass:"white--text",attrs:{"timeout":3000,"color":"red darken-2"},model:{value:(_vm.x.error),callback:function ($$v) {_vm.$set(_vm.x, "error", $$v)},expression:"x.error"}},[_vm._v(_vm._s(_vm.x.error))]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }