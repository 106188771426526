<template>
  <div>
    <!-- <v-layout  v-if="!reportType.includes('Sales Report')  ||reportType.includes('Purchase Report') || reportType.includes('Advance/Pending Payment Report')">
    Seems like you don't have any permissions. Please contact
        administrator
    </v-layout> -->
    <v-layout
      class="pt-3"
      v-if="!reportType.length"
      style="justify-content: center"
    >
      Seems like you don't have any permissions. Please contact administrator
    </v-layout>
    <v-layout v-if="reportType.length">
      <v-flex xs3>
        <div class="muted caption">Select Report</div>
        <v-select
          label="Select Report"
          :items="reportType"
          v-model="selectReport"
          single-line
          @change="changeReport()"
          class="pt-0 currencyTitle"
        />
      </v-flex>
      <v-flex xs4 pl-3 v-if="selectReport === 'Transporter Driver Payment'">
        <v-autocomplete
          :items="searchItems"
          flat
          label=" Filter By Assignment Status"
          item-text="status"
          item-value="abbr"
          v-model="assignStatus"
          multiple
          autocomplete="offfadsf"
        />
      </v-flex>
      <v-flex xs1 pl-3 v-if="selectReport === 'Transporter Driver Payment'">
        <v-autocomplete
          :items="filterDriver"
          flat
          label="Group By"
          item-text="status"
          item-value="abbr"
          v-model="SortStatus"
          single-line
          autocomplete="offfadsf"
        />
      </v-flex>
      <v-flex xs2 pl-2 class="wrapper pt-17" id="accountDate">
        <ejs-daterangepicker
          :placeholder="waterMark"
          v-model="dateRangeAdvance"
        ></ejs-daterangepicker>
      </v-flex>

      <v-flex xs1 class="reset-btn reset__btn_Dashboard">
        <v-card-actions class="justify-center">
          <v-btn
            flat
            class="reset-btn-color"
            prepend-icon
            color="orange darken-1"
            @click.native="resetBtn()"
            :value="reset"
          >
            Reset
            <v-icon color="orange">donut_large</v-icon>
          </v-btn>
        </v-card-actions>
      </v-flex>
      <v-flex xs1 class="reset-btn download__btn pl-3" v-if="dateRangeAdvance">
        <v-card-actions class="justify-center">
          <v-btn
            flat
            class="reset-btn-color"
            prepend-icon
            color="orange darken-1"
            @click.native="downloadBtn()"
            :value="download"
          >
            Download
            <v-icon color="orange">download</v-icon>
          </v-btn>
        </v-card-actions>
      </v-flex>
    </v-layout>

    <sales-report
      v-permissions="'sales-dashboard/true'"
      v-if="selectReport === 'Sales Report'"
      :reportType="selectReport"
      :download="download"
      :dateRangeFilter="dateRangeAdvance"
      :reset="reset"
    />
    <purchase-advance
      v-permissions="'purchase-dashboard/true'"
      v-else-if="selectReport === 'Purchase Report'"
      :reportType="selectReport"
      :download="download"
      :dateRangeFilter="dateRangeAdvance"
      :reset="reset"
    />

    <advance-payment-report
      v-else-if="selectReport === 'Advance/Pending Payment Report'"
      :reportType="selectReport"
      v-permissions="'advance-dashboard/true'"
      :download="download"
      :dateRangeFilter="dateRangeAdvance"
      :reset="reset"
    />
    <assignmentFinanicalReport
      v-permissions="'finance-dashboard/true'"
      v-else-if="selectReport === 'Financial Report of Assignment'"
      :reportType="selectReport"
      :download="download"
      :dateRangeFilter="dateRangeAdvance"
      :reset="reset"
    />
    <completeAssignmentDetails
      v-permissions="'finance-dashboard/true'"
      v-else-if="selectReport === 'Complete Assig. Level Details'"
      :reportType="selectReport"
      :download="download"
      :dateRangeFilter="dateRangeAdvance"
      :reset="reset"
    />

    <assignmentKsaReport
      v-permissions="'ksa-report-dashboard/true'"
      v-else-if="selectReport === 'Assignment KSA Report'"
      :reportType="selectReport"
      :download="download"
      :dateRangeFilter="dateRangeAdvance"
      :reset="reset"
    />
    <TransporterDriverPayment
      v-permissions="'finance-dashboard/true'"
      v-else-if="selectReport === 'Transporter Driver Payment'"
      :reportType="selectReport"
      :download="download"
      :dateRangeFilter="dateRangeAdvance"
      :searchStatus="assignStatus"
      :sortBy="SortStatus"
      :reset="reset"
    />
    <uninvoiceAssign
      v-permissions="'uninvoiced-dashboard/true'"
      v-else-if="selectReport === 'Uninvoice Assignments'"
      :reportType="selectReport"
      :download="download"
      :dateRangeFilter="dateRangeAdvance"
      :reset="reset"
    />
    <uninvoiceJob
      v-permissions="'uninvoiced-dashboard/true'"
      v-else-if="selectReport === 'Uninvoice Jobs'"
      :reportType="selectReport"
      :download="download"
      :dateRangeFilter="dateRangeAdvance"
      :reset="reset"
    />
  </div>
</template>
<script>
import salesReport from "./salesReport.vue";
import purchaseAdvance from "./purchaseAdvance.vue";
import assignmentFinanicalReport from "./assignmentFinanicalReport";
import TransporterDriverPayment from "./TransporterDriverPayment";
import uninvoiceAssign from "./uninvoiceAssign";
import uninvoiceJob from "./uninvoiceJob";

import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { StorageKeys } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
Vue.use(DateRangePickerPlugin);
import advancePaymentReport from "./advancePaymentReport.vue";
import completeAssignmentDetails from "./completeAssignmentDetails.vue";
import assignmentKsaReport from "./assignmentKsaReport.vue";

export default {
  components: {
    advancePaymentReport,
    salesReport,
    purchaseAdvance,
    assignmentKsaReport,
    assignmentFinanicalReport,
    completeAssignmentDetails,
    uninvoiceAssign,
    uninvoiceJob,
    TransporterDriverPayment,
  },
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
    }

    this.getPermissionBasedReportType();
    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      this.getPermissionBasedReportType();
      this.dateRangeAdvance = null;
    });
  },
  watch: {
    dateRangeAdvance(val) {},
  },
  props: {
    currentTab: String,
  },
  data() {
    return {
      selectReport: "Sales Report",
      assignStatus: [],
      SortStatus: "Transporter",

      searchItems: [
        {
          status: "PAYMENT DONE",
          abbr: "PAYMENT_DONE",
        },
        { status: "PAYMENT PENDING", abbr: "PAYMENT_PENDING" },
        { status: "DOC RECEIVED", abbr: "DOC_RECEIVED" },
        {
          status: "SHIPEMENT OFFLOADED",
          abbr: "SHIPEMENT_OFFLOADED_POD_AWAITED",
        },
        { status: "DRIVER ASSIGNED", abbr: "DRIVER_ASSIGNED" },
        { status: "IN TRANSIT", abbr: "IN_TRANSIT" },
      ],
      dateRangeAdvance: null,
      reportType: [],
      operationName: "",
      filterDriver: [
        {
          status: "Driver",
          abbr: "Driver",
        },
        { status: "Transporter", abbr: "Transporter" },
      ],
      workingCountry: null,
      reset: "false",
      download: false,
      waterMark: "Date Range Filter",
    };
  },
  methods: {
    async getPermissionBasedReportType() {
      let roles = JSON.parse(localStorage.getItem("permissions")).roles;
      roles = roles.map((x) => x.role.roleTitle);

      this.reportType = [];

      if (roles.includes("Sales Dashboard")) {
        this.reportType.push("Sales Report");
      }
      if (roles.includes("Purchase Dashboard")) {
        this.reportType.push("Purchase Report");
      }
      if (roles.includes("Advance Dashboard")) {
        this.reportType.push("Advance/Pending Payment Report");
      }
      if (roles.includes("Finance Dashboard")) {
        this.reportType.push("Financial Report of Assignment");
      }
      if (roles.includes("UnInvoiced Dashboard")) {
        this.operationName = "un-invoiced-jobs";

        let y = await this.checkOpertaionPermission(this.operationName);
        console.log(y);
        if (y) {
          this.reportType.push("Uninvoice Jobs");
        }
      }
      if (roles.includes("UnInvoiced Dashboard")) {
        this.operationName = "un-invoiced-assignment";

        let y = await this.checkOpertaionPermission(this.operationName);
        console.log(y);
        if (y) {
          this.reportType.push("Uninvoice Assignments");
        }
      }

      if (roles.includes("Finance Dashboard")) {
        this.operationName = "driver-transporter-report";

        let y = await this.checkOpertaionPermission(this.operationName);

        if (y) {
          this.reportType.push("Transporter Driver Payment");
        }
      }
      // if (roles.includes("Finance Dashboard")) {
      //   this.operationName = "Assignment-Complete-Report";

      //   let y = await this.checkOpertaionPermission(this.operationName);
      //   console.log(y);
      //   if (y) {
      //     this.reportType.push("Complete Assig. Level Details");
      //   }
      // }

      if (
        roles.includes("KSA Report Dashboard") &&
        this.workingCountry.includes("KSA")
      ) {
        this.operationName = "Assignment-KSA-Report";
        let y = await this.checkOpertaionPermission(this.operationName);

        if (y) {
          this.reportType.push("Assignment KSA Report");
        }
      }
    },
    changeReport() {
      this.dateRangeAdvance = null;
    },
    resetBtn() {
      this.dateRangeAdvance = null;
      this.assignStatus = [];
      this.SortStatus = "Transporter";

      // this.reset = this.reset = "true"
      //   ? (this.reset = "false")
      //   : (this.reset = "true");
    },
    downloadBtn() {
      this.download = !this.download;
    },
  },
};
</script>
<style scoped>
.pt-17 {
  padding-top: 17px !important ;
}
</style>
>
<style lang="scss">
.card--raised {
  border: 1px solid orange !important;
}
#filter_data_toggle .flex.xs2.pr-2 > div {
  padding-top: 0 !important;
}
.divider-style {
  margin-bottom: 24px;
  margin-top: 15px;
  background: lightgrey;
}
.reset__btn_Dashboard {
  position: absolute;
  right: 0;
}
.download__btn {
  position: absolute;
  margin-right: 127px;
  right: 0;
}
.gap {
  position: relative;
  right: 2px;

  z-index: auto;
}
// .date_9 input#ej2-datetimepicker_0 {
//   font-size: 16px;
// }
.searchAccounts .v-label.v-label--is-disabled.theme--light {
  left: 10px !important;
}
.tooltip {
  font-size: 13px;
  width: auto !important;
  font-weight: 500;
}
.tooltip-op {
  opacity: 1 !important;
}
.searchAccounts .theme--light.v-label {
  left: 10px !important;
}
#accountDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
.searchAccounts input {
  padding-left: 10px !important;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  font-size: 16px !important;
}

thead {
  background: #fbfbfb;
}
</style>
