<template>
  <v-card class>
    <loading :active.sync="loading" />
    <v-container fluid>
      <v-layout row class="pb-2"> </v-layout>
    </v-container>
    <v-card-text class="nospace" pt-1>
      <v-data-table
        id="jobsTable"
        class="word___Break"
        hide-actions
        :disable-initial-sort="true"
        :headers="headers"
        :items="items"
      >
        <template slot="items" slot-scope="props">
          <td>
            Id: {{ props.item["Job Id"] }} <br />{{
              props.item["Job Start Date"]
                ? getTime4(props.item["Job Start Date"])
                : ""
            }}
          </td>
          <td>
            {{ props.item["Assignment Id"] }}
          </td>

          <td>{{ props.item["Status"] }}</td>
          <td>{{ props.item["Purchase Invoice"] }}</td>
          <td>
            {{
              props.item["Purchase Invoice Generated At"]
                ? getTime4(props.item["Purchase Invoice Generated At"])
                : "-"
            }}
          </td>
          <td>
            {{ props.item["Driver Id"] }} <br />{{ props.item["Driver Name"] }}
            <br />{{ props.item["Driver Phone No"] }}
          </td>
          <td>{{ props.item["Vehicle Number"] }}</td>
          <td>
            {{ props.item["Driver Accepted Rate"] }}
            {{ props.item["Customer Currency"] }}
          </td>
          <td>
            {{ props.item["Additional Charge"] }}
            {{ props.item["Customer Currency"] }}
          </td>
          <td>
            {{ props.item["Advance Amount Requested"] }}
            {{ props.item["Customer Currency"] }}
          </td>
          <td>
            {{
              props.item["Advance Requested At"]
                ? convertDateArray(props.item["Advance Requested At"])
                : "-"
            }}
          </td>
          <td>
            {{ props.item["Advance Amount Paid"] }}
            {{ props.item["Customer Currency"] }}
          </td>
          <td>
            {{
              props.item["Advance Paid At"]
                ? convertDateArray(props.item["Advance Paid At"])
                : "-"
            }}
          </td>
          <td>{{ props.item["VAT"] }} {{ props.item["Customer Currency"] }}</td>
          <td>
            {{ props.item["Total Purchases"] }}
            {{ props.item["Customer Currency"] }}
          </td>
          <td>
            {{ props.item["Pending Payment"] }}
            {{ props.item["Customer Currency"] }}
          </td>
        </template>
        <v-alert
          slot="no-results"
          :value="isNoSearchResult"
          color="blue"
          icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
      <pagination
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :componentKey="componentKey"
        :type="0"
        :url="paginationURL"
        :callback="callback"
        :action="`advancePaymentReport`"
        v-on:pagination-load="checkLoading"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
let momentTz = require("moment-timezone");
import { advancePaymentReport } from "@/constants/datatable-headers.js";
import pagination from "@/components/Pagination/pagination";
import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { StorageKeys } from "../../constants/constants";

Vue.use(DateRangePickerPlugin);
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Loading from "vue-loading-overlay";
import { EventBus } from "../../event-bus.js";
import downloadMixin from "@/mixins/downloadMixin";
export default {
  mixins: [downloadMixin],
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });

      this.workingCountry = JSON.stringify(workingCountry);
      this.paginationURL = `/dmsAdmin/advancedReport?workingCountry=${this.workingCountry}`;
      this.loading = false;
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);

      if (this.reportType == "Advance/Pending Payment Report") {
        this.paginationURL = `/dmsAdmin/advancedReport?workingCountry=${this.workingCountry}`;
      }
    });
  },
  components: {
    pagination,
    Loading,
  },
  props: {
    reportType: String,
    dateRangeFilter: Array,
    reset: String,
    download: Boolean,
  },
  watch: {
    dateRangeFilter(val) {
      if (this.reportType == "Advance/Pending Payment Report") {
        if (val) {
          if (val[0] && val[1]) {
            this.startDate = moment(val[0]).format("YYYY-MM-DD");
            this.endDate = moment(val[1]).format("YYYY-MM-DD");
          }
          this.searchData(null, val);
        } else {
          this.startDate = "";
          this.endDate = "";

          this.searchData(null, val);
        }
      }
    },
    reset(val) {
      if (this.reportType == "Advance/Pending Payment Report") {
        this.paginationURL = `/dmsAdmin/advancedReport?workingCountry=${this.workingCountry}`;
      }
    },
    download(val) {
      if (this.reportType == "Advance/Pending Payment Report") {
        this.downloadFile();
      }
    },
  },
  data() {
    return {
      // ----- NOTIFICATION ---
      waterMark: "Select a Range (Advance Payment Date)",
      // dateRangeAdvance: null,
      startDate: "",
      endDate: "",
      loading: true,
      headers: advancePaymentReport,
      paginationURL: "",
      items: [],
      pagination: {},
      totalPages: 0,
      isNoSearchResult: false,
      pages: 1,
      search: "",
      componentKey: 0,
    };
  },
  methods: {
    convertToDecimal(value) {
      let number = parseFloat(value);

      return Number.isInteger(number) ? number : number.toFixed(2);
    },

    downloadFile() {
      let url = [];
      let timezone = momentTz.tz.guess();
      const date = new Date();
      url.push(`workingCountry=${this.workingCountry}`);
      url.push(`offset=${date.getTimezoneOffset()}`);

      url.push(`startDate=${this.startDate}`);
      url.push(`endDate=${this.endDate}`);

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let finalUrl = "/dmsAdmin/advancedReportDownload?";
      if (url.length > 0) {
        let params = url.join("&");
        finalUrl = finalUrl + params;
      } else return "";
      let fileName = `advance_payment_report_${this.startDate}_to_${this.endDate}`;
      this.DownloadExcel(this.constants.apiUrl + finalUrl, fileName);
    },

    searchData(jobStatusVal = null, dateRangeVal = null) {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }

      let url = [];
      url.push(`workingCountry=${this.workingCountry}`);

      if (dateRangeVal) {
        url.push(`startDate=${this.startDate}`);
        url.push(`endDate=${this.endDate}`);
      } else if (this.startDate.length > 0) {
        url.push(`startDate=${this.startDate}`);
        url.push(`endDate=${this.endDate}`);
      }

      let finalUrl = `/dmsAdmin/advancedReport?`;
      if (url.length > 0) {
        let params = url.join("&");
        finalUrl = finalUrl + params;
      } else return "";

      this.paginationURL = finalUrl;
    },
    convertDateArray(dateTime) {
      if (dateTime) {
        let date = dateTime.split(",");
        return date.map((x) => moment(x).format("ll HH:mm")).join(" ; ");
      } else {
        return "-";
      }
    },
    getTime4(date) {
      return moment(date).format("ll HH:mm");
    },

    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    page(event) {
      this.pages = event;
    },
    async callback({ list, totalData }) {
      this.items = list;
      this.pagination.rowsPerPage = 15;
      this.totalPages = Math.ceil(totalData / 15);
      this.$emit("job-load", false);
      window.scrollTo(0, 0);
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.card--raised {
  border: 1px solid orange !important;
}
#filter_data_toggle .flex.xs2.pr-2 > div {
  padding-top: 0 !important;
}
.divider-style {
  margin-bottom: 24px;
  margin-top: 15px;
  background: lightgrey;
}
.reset__btn {
  position: absolute;
  right: 0;
}
.download__btn {
  position: absolute;
  margin-right: 127px;
  right: 0;
}
.gap {
  position: relative;
  right: 2px;

  z-index: auto;
}
// .date_9 input#ej2-datetimepicker_0 {
//   font-size: 16px;
// }
.searchAccounts .v-label.v-label--is-disabled.theme--light {
  left: 10px !important;
}
.tooltip {
  font-size: 13px;
  width: auto !important;
  font-weight: 500;
}
.tooltip-op {
  opacity: 1 !important;
}
.searchAccounts .theme--light.v-label {
  left: 10px !important;
}
.searchAccounts input {
  padding-left: 10px !important;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  font-size: 16px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
#filter-toggle {
  display: none;
}
.pd-left {
  padding-left: 5px !important;
}
.address_td {
  width: 10%;
  word-break: break-all;
}
.pd-t-l {
  padding-left: 5px;
  padding-top: 7px;
}
.track-ass-btn {
  text-decoration: none;
  margin: 7px !important;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 6px 15px;
}
.total.avatar .count {
  background-color: darkorange;
  color: white !important;
  padding: 0 5px;
  font-size: 18px !important;
  border-radius: 5px;
  margin-left: 10px;
}
.total.avatar {
  min-width: 280px !important;
  height: 50px !important;
  /* background-color: #ff8c002b !important; */
  /* border-bottom: 3px solid darkorange !important; */
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 0;
}
.total.avatar span {
  color: darkorange !important;
}

.status-count {
  font-size: 16px;

  font-weight: 900 !important;
}
.reset-btn {
  float: right;
  margin-top: 6px;
}
.avatar {
  border-radius: 10px;
}
.viewDetail {
  cursor: pointer;
}
.job-detail {
  margin: auto 0;
}
.theme--light.menuable__content__active {
  min-width: 290px !important;
}
.tooltip {
  font-size: 13px;
  width: auto !important;
  font-weight: 500;
}
.tooltip-op {
  opacity: 1 !important;
}
.text-center {
  text-align: center !important;
}

.date-picker {
  min-width: 100%;
}

.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.track-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 5px;
}

.cancel-btn {
  text-decoration: none;
  color: #ffffff;
  border: 2px solid red;
  padding: 2px;
  margin-top: 5px;
  background-color: #f38282;
  border-radius: 5px;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}
.reset-btn-color {
  background-color: #ffe7c9 !important;
}
.p-16 {
  padding: 0 16px !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.minHeight {
  min-height: 300px;
}
.colorchange {
  background-color: #fae3e3 !important;
}
.colorchangeyellow {
  background-color: #ffffe0 !important;
}
.spacerclass {
  max-height: 550px !important;
}
.m-t-10 {
  margin-top: 10px;
}

.filter {
  padding: 20px;
  margin: 0 auto;
}
.reset {
  margin-top: 10px;
}
.gap {
  position: relative;
  right: 2px;

  z-index: auto;
}
.reset-btn-color {
  background-color: #ffe7c9 !important;
}
.word___Break {
  word-break: break-word !important;
}
.button-red {
  background-color: #ff7b7b !important;
  color: #ffffff !important;
  border: 2px solid red !important;
  margin-top: 5px !important;
  margin-left: 25px !important;
}
</style>
