<template>
  <v-container fluid>
    <v-card raised>
      <loading :active.sync="loading" />

      <v-tabs
        fixed-tabs
        style="
          text-align: center;
          align-items: center;
          justify-content: center;
          display: flex;
        "
        v-model="currentTab"
        background-color="darkorange"
        dark
      >
        <v-tab :href="`#redash`" @click="viewTab('redash')">Qlik Sense</v-tab>
        <!-- <v-tab
          :href="`#customerDashboard`"
          @click="
            viewTab('customerDashboard');
            loading = true;
          "
          v-permissions="'customer-summary-dashboard/true'"
          >Customer Dashboard</v-tab
        >
        <v-tab :href="`#reports`" @click="viewTab('reports')">Reports</v-tab> -->

        <!-- <v-tab
          :href="`#salesReport`"
          @click="viewTab('salesReport')"
          v-permissions="'sales-dashboard/true'"
          >sales Report</v-tab
        >
        <v-tab
          :href="`#purchaseReport`"
          @click="viewTab('purchaseReport')"
          v-permissions="'purchase-dashboard/true'"
          >Purchase Report</v-tab
        >
        <v-tab
          :href="`#advancePaymentReport`"
          @click="viewTab('advancePaymentReport')"
          >Advance Payment Report</v-tab
        > -->
      </v-tabs>

      <v-flex
        :href="`#redash`"
        class="m-t-20"
        v-if="currentTab == 'redash'"
        md12
      >
        <redash :currentTab="currentTab" />
      </v-flex>
      <v-flex
        :href="`#customerDashboard`"
        class="m-t-20"
        v-if="currentTab == 'customerDashboard'"
        md12
      >
        <customerDashboard :currentTab="currentTab" />
      </v-flex>
      <v-flex
        :href="`#reports`"
        class="m-t-20"
        v-if="currentTab == 'reports'"
        md12
      >
        <reports :currentTab="currentTab" />
      </v-flex>

      <!-- <v-flex
        :href="`#salesReport`"
        class="m-t-20"
        v-if="currentTab == 'salesReport'"
        md12
      >
        <salesReport :currentTab="currentTab" />
      </v-flex>
      <v-flex
        :href="`#purchaseReport`"
        class="m-t-20"
        v-if="currentTab == 'purchaseReport'"
        md12
      >
        <purchaseAdvance :currentTab="currentTab" />
      </v-flex>
      <v-flex
        :href="`#purchaseReport`"
        class="m-t-20"
        v-if="currentTab == 'purchaseReport'"
        md12
      >
        <purchaseAdvance :currentTab="currentTab" />
      </v-flex>
      <v-flex
        :href="`#advancePaymentReport`"
        class="m-t-20"
        v-if="currentTab == 'advancePaymentReport'"
        md12
      >
        <advancePaymentReport :currentTab="currentTab" />
      </v-flex> -->
    </v-card>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import { StorageKeys } from "../../constants/constants";
import redash from "./redash";
import customerDashboard from "./customerDashboard";
import reports from "./reports";
import salesReport from "./salesReport.vue";
import purchaseAdvance from "./purchaseAdvance.vue";
import moment from "moment";
import { EventBus } from "../../event-bus.js";
import Redash from "./redash.vue";

export default {
  mounted() {
    if (localStorage.getItem("tabAnalaytics") != null) {
      this.currentTab = localStorage.getItem("tabAnalaytics");
      this.viewTab(this.currentTab);
    } else {
      this.currentTab = "Redash";
      this.viewTab(this.currentTab);
    }
  },
  created() {
    this.track("Redash", {
      $email: localStorage.getItem("email"),
      $name: localStorage.getItem("user"),
      WorkingCountry: localStorage.getItem("workingCountry"),
    });
    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
    this.track("Redash", {
      $email: localStorage.getItem("email"),
      $name: localStorage.getItem("user"),
      WorkingCountry: localStorage.getItem("workingCountry"),
    });
  },
  components: {
    Loading,
    redash,
    customerDashboard,
    reports,
    salesReport,
    purchaseAdvance,
  },
  data() {
    return {
      loading: true,
      currentTab: "redash",
      TabStatus: "redash",
      isBid: false,
    };
  },
  props: {
    jobId: Number,
    _id: String,
  },

  methods: {
    viewTab(flag) {
      if (flag == "redash") {
        localStorage.setItem("tabAnalaytics", flag);
        this.track("Redash", {
          $email: localStorage.getItem("email"),
          $name: localStorage.getItem("user"),
          WorkingCountry: localStorage.getItem("workingCountry"),
        });
      } else if (flag == "salesReport") {
        localStorage.setItem("tabAnalaytics", flag);
      } else if (flag == "purchaseReport") {
        localStorage.setItem("tabAnalaytics", flag);
      } else {
        localStorage.setItem("tabAnalaytics", flag);

        this.track("Customer Dashboard", {
          $email: localStorage.getItem("email"),
          $name: localStorage.getItem("user"),
          WorkingCountry: localStorage.getItem("workingCountry"),
        });
      }
      this.currentTab = flag;
    },

    getTime(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
  },
};
</script>

<style></style>
